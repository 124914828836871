.page.messages {
  padding: 0 $size-4xl;

  @include breakpoint($desktop) {
    max-width: 1178px;
    margin: 0 auto $size-2xl auto;
    padding: 0;
  }

  .success,
  .error,
  .warning,
  .notice {
    padding: $size-2xs 52px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: $size-2xs;
      left: $size-l;
      height: $size-l;
      width: $size-l;
    }
  }

  .success {
    background: $brand-senary;
    color: $white;

    &::before {
      background: url("../images/icons/icon-success.svg");
      background-size: $size-l;
    }

    a,
    a:visited,
    a:focus,
    a:active,
    a:hover {
      color: $white;
    }
  }

  .error {
    background: $error;
    color: $white;

    &::before {
      background: url("../images/icons/icon-error.svg");
      background-size: $size-l;
    }

    a,
    a:visited,
    a:focus,
    a:active,
    a:hover {
      color: $white;
    }
  }

  .warning {
    background: $warning;
    color: $grey-dark;

    &::before {
      background: url("../images/icons/icon-warning.svg");
      background-size: $size-l;
    }
  }
}

.catalog-category-view {
  .page.messages {
    @include breakpoint(max-width $tablet) {
      padding: 0 $size-m;
      margin-bottom: $size-m;
    }
  }
}

.message.info {
  background: $brand-background;
  color: $brand-quinary;
  font-weight: $fontReg;
  padding: $size-2xs $size-m;
  margin-bottom: $size-m;

  &:last-of-type {
    margin-bottom: 0;
  }
}
