.product-reviews__wrapper {
  padding: $size-4xl $size-4xl $size-2xl $size-4xl;
  border-bottom: 1px solid $border-lighter;

  @include breakpoint($desktop) {
    padding: $size-7xl 0;
  }
}

.catalog-product-view {
  div[class*="trustpilot-widget-trustbox"] {
    margin: 0 auto;
  }
}
