$susy: (
  "columns": susy-repeat(16),
  "gutters": 32px,
  "spread": "narrow",
  "container-spread": "narrow",
);

body {
  box-sizing: border-box;
  
  * {
    box-sizing: inherit;
  }
}

//
// 100% width page blocks
// _With_ outer gutter
//

%full-width-outer-gutter,
.page-header,
.footer__newsletter-constrainer,
.footer__social {
  width: span(16);
  max-width: 100%;
  padding: 0 $size-m;
}

//
// Full width content
//

.full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

//
// 100% width page blocks
// With _no_ gutter
// 

.cms-page-view .page-main [data-content-type="row"][data-appearance="full-width"],
.page-wrapper,
.page-footer,
.footer.content,
.plp-header {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

//
// Contrained page blocks
//

%constrainer,
.header.content,
.breadcrumbs,
.catalog-product-view .page-main,
.catalog-product-view .product-tabs__wrapper,
.catalog-product-view .product-tabs__wrapper--tab div[class*="section--"],
.footer__constrainer,
.footer__newsletter-constrainer,
.footer__social,
.products-grid,
.customer-account-create .column.main,
.checkout-cart-index .column.main,
.logout-page-container {
  max-width: 1168px;
  margin: 0 auto;
}



.page-wrapper {
  padding-top: 120px; // height of sticky header

  @include breakpoint($desktop) {
    padding-top: 152px;
  }
}


.ie11 {
  
  .pagebuilder-column-group,
  figure {
    min-height: 1px; // seems a bit hacky but its a known flexbug with images inside flex items on IE11
  }
}

.bg-primary-purple-light {
  background-color: #d6cddd;
}

.container.promo-container {
  padding-bottom: 50px;
}

//Home Insta Feed Styling
.row.insta-before-after {
  margin-left: 0;
  margin-right: 0;
}

.treatment-gallery-heading {
  display: flex;
  justify-content: center;
  font-size: 25px;
  color: #fff;
  font-family: futura-pt;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 23px 0 !important;
}

h3.scripted {
  font-family: imogen-agnes;
  text-transform: none;
  letter-spacing: -0.8px;
  color: #fff;
  text-align: center;
  font-size: 26px;
  margin: -15px 0 25px 0;
}

.icon-holder {
  position: relative;
  font-family: futura-pt;
  font-weight: 100;
  font-size: 13px;
  text-align: center;
  padding: 8px 0 6px 0;
  margin: 16px 0 16px 0;
  z-index: 1;
}

.icon-holder:before {
  border-top: 1px solid #fff;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.icon-holder span {
  background: #d6cddd;
  padding: 0 15px;
}

.insta-logo {
  width: 40px;
}

//FAQ Styling - Content Type
.n_section .n_section__content {
  .ct-group.accordion {
    h3 p {
      color: $brand-primary;
      font-size: 18px !important;
      font-weight: $fontBook;
    }
    a {
      cursor: pointer;
      text-decoration: none;
    }
  }
}

/* Section Slick Slider */
.n_section__content--pad-doub--slider {
  .slick-slider {
    padding: 0;

    .slick-track {
      display: flex;
    }

    .slick-slide {
      margin-right: 0.5%;
      @include breakpoint($desktop) {
        margin-right: 2%;
      }
    }

    .slick-dots {
      bottom: -40px;
      li.slick-active {
        background-color: #717171;
      }
      li {
        cursor: pointer;
        height: 4px;
        width: 30px;
        margin: 0 2px;
        background-color: #bbb;
        display: inline-block;
        transition: background-color 0.6s ease;
      }
      li button:before {
        display: none;
      }
    }
  }
}

.purple-light {
  padding: 30px 0 !important;
  background-color: #f7f2f8;
}

#salon {
  @include breakpoint($desktop) {
    padding: 3% 0;
  }
}

#salon-holder {
  @include breakpoint($desktop) {
    display: flex;
    justify-content: center;
  }
  .col-xs-12.col-md-6 {
    @include breakpoint($desktop) {
      width: 30%;
      margin-right: 8.33333333%;
    }
  }
}

#salon-holder,
.salon-head {
  text-align: center;
}

span.sf-input-wrap {
  border: 1px solid $brand-primary;
  @include breakpoint($desktop) {
    border: none;
  }
}

form#salon-form {
  @include breakpoint($desktop) {
    border: 1px solid $brand-primary;
    padding-bottom: 40px;
  }
}