//
// Breakpoints
//

$smallMobile: 360px;
$mobile: 420px;
$tablet: 600px;
$desktop: 1025px;
$desktop-mid: 1200px;
$desktop-large: 1440px;

//
// Fonts
// Futura PT Book
// Futura PT Medium
// Lust Pro Demi No 5
// Imogen Agnes Regular
//

$fontBody: "futura-pt", Helvetica, Arial, sans-serif;
$fontHeading: "lust-didone", serif;

$fontBold: 500;
$fontBook: 400;
$fontReg: 300;

//
// Colours
//

$brand-primary: #2F1A45;
$brand-secondary: #422B66;
$brand-tertiary: #A587BE;
$brand-quaternary: #6F4B99;
$brand-quinary: #AB699F;
$brand-senary: #8A6DAE;
$brand-violet: #83738E;
$brand-background: #F2F1F7;
$brand-pale: #F1EBF4;
$brand-vpale: #F9F8FC;
$brand-symbol: #827CB2;
$brand-noroute: #cbb7de;
$brand-low-availability: #DDC5E1;

$kit-bronze: #C18142;
$kit-silver: #C0C0C0;
$kit-gold: #D3C160;
$kit-default: #83738E;

$grey-dark: #474848;
$grey-light: #999A9A;
$grey-lighter: #DEDEDE;
$grey-lightest: #F5F5F5;
$white: #fff;

$border-light: #D8D5E7;
$border-lighter: #DEDEDE;

$success: #4BB543;
$error: #D91D28;
$warning: #FDAC35;
$background: #F9F8FC;

$header-lilac: #8b85b8;

//
// Sizing and Spacing
//

$size-9xl: 88px;
$size-8xl: 80px;
$size-7xl: 64px;
$size-6xl: 48px;
$size-5xl: 44px;
$size-4xl: 40px;
$size-3xl: 36px;
$size-2xl: 32px;
$size-xl: 24px;
$size-l: 18px;
$size-m: 16px;
$size-s: 14px;
$size-xs: 12px;
$size-2xs: 8px;
$size-3xs: 4px;
