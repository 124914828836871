.customer-account-create {

  .register-container {

    @include breakpoint($desktop) {
      max-width: span(8);
      margin: 0  auto;
    }

    .page-title {
      @include fancyHeading;
      font-weight: 400;
      margin: 0 0 $size-2xl;
      padding: 0 $size-m;
      text-align: center;
    }
  
    .form-create-account {
      padding: 0;
    }
  
    .fieldset {
      padding: 0 $size-4xl $size-xl $size-4xl;
      background: $brand-vpale;
      margin-bottom: $size-2xl;

      .fieldset {
        padding: 0;
      }
    }
  
    .legend {
      @include largeHeading;
      display: block;
      margin: $size-xl 0 $size-m;
      text-transform: uppercase;
    }
  
    .field {
  
      &:last-child {
  
        input {
          margin-bottom: 0;
        }
      }
    }

    .actions-toolbar {
      padding: 0 $size-4xl;

      @include breakpoint($desktop) {
        padding: 0;
      }

      .action.submit {
        @extend %secondary-button;
        width: 100%;
        margin-bottom: $size-2xl;
        margin-top: 0;
      }
    }

    .sign-in-link {
      @extend %link-style-button;
      text-align: center;
      display: block;

      &:hover {
        cursor: pointer;
      }
    }

    .field-name-firstname label,
    .field-name-lastname label {
      &::after {
        content: "*";
        font-size: 13px;
      }
    }

    .field.terms_and_conditions {
      margin-bottom: $size-2xl;
      padding: 0 $size-4xl;

      input[value="0"] {
        display: none;
      }

      @include breakpoint($desktop) {
        padding: 0;
      }
    }

    input[type="checkbox"] + label {
      text-transform: none;
      font-weight: $fontReg;
      display: inline-block;
      width: calc(100% - 30px);
      
      a {
        text-decoration: underline;
      }
    }

    .field--checkboxes {

      input:first-child {
        display: none;
      }

      label:nth-child(2) {
        display: none;
      }

      label {
        margin-bottom: 0;
      }
    }

    .field.your_qualifications label {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    #region {
      display: block !important; // needed to override inline style added by something or other
    }

    label[for="region_id"]::after {
      content: "";
    }

    label[for="street_3"] {
      display: none;
    }

    .field.street {
      margin-bottom: $size-m;
    }

    .mage-error {
      color: $error;
      font-size: $size-s;
      margin-bottom: $size-2xs;
    }

  } 
}
