.footer {

  &__newsletter {
    background: $brand-pale;
    padding: $size-xl 0;
    box-shadow: inset 0 1px 0 0 $grey-lighter, inset 0 -1px 0 0 $grey-lighter;

    &-constrainer {

      @include breakpoint($desktop) {
        padding: 0 span(4);
      }

      @include breakpoint(1580px) {
        padding: 0 360px;
      }
    }

    .footer__newsletter-title {
      @include subHeading;
      @include fontSize(16px);
      line-height: 20px;
      margin: 0 0 $size-2xs;
      text-align: center;
      display: block;
    }

    p {
      line-height: $size-xl;
      padding: 0 span(.5 of 4);
      text-align: center;
      margin-bottom: $size-m;

      @include breakpoint($desktop) {
        padding: 0;
      }
    }
  }

  &__newsletter-cta {
    @extend %tertiary-button;
    width: 182px; // from design
    margin: 0 auto;
    display: block;
  }
}

.modal-newsletter-signup {

  .modal-header .modal-title {

    &::before {
      background: url(../images/icons/icon-contact.svg) 50% 50% no-repeat;
      background-size: cover;
      position: relative;
      top: 2px;
    }
  }

  .modal-content {
    // padding: 0;

    .newsletter {

      > h3,
      > p {
        padding-left: $size-4xl;
        padding-right: $size-4xl;
      }

      > h3 {
        margin: $size-4xl 0 $size-m;
      }

      > p {
        margin-bottom: 0;
      }

      .content {
        padding-left: $size-m;
        padding-right: $size-m;
      }

      .actions {
        margin-bottom: $size-xl;
      }

      .action.subscribe {
        @extend %tertiary-button;
        width: 100%;
      }

      &__privacy {

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
