$ribbon-bg-color: #B068A8 !default;
$ribbon-text-color: #ffffff !default;

.ribbon-banner {
  min-height: 40px;
  display: block;
  background-color: $ribbon-bg-color;
  color: $ribbon-text-color;
  margin-left: -1rem;
  margin-right: -1rem;

  &__content {
    max-width: 1168px;
    margin: 0 auto;
    text-align: center;
    padding: 0.5rem;

    p {
      color: $ribbon-text-color;
    }
  }
}