// Placeholder which can be @extend 'ed where needed.
// Taken from the WebAIM site - https://webaim.org/techniques/css/invisiblecontent/
%visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// undo visually-hidden styles
%visually-unhidden {
  position: static;
  width: auto;
  height: auto;
}

// Utility classes
// Must use a u- prefix
// Keep to a minimum

%u-mobile-only,
.u-mobile-only {
  
  @include breakpoint($tablet) {
    display: none;
  }
  
  @include breakpoint($desktop) {
    display: none;
  }
}

%u-tablet-only,
.u-tablet-only {
  display: none;
  
  @include breakpoint($tablet) {
    display: inline-block;
    display: initial;
  }
  
  @include breakpoint($desktop) {
    display: none;
  }
}

%u-desktop-only,
.u-desktop-only {
  display: none;
  
  @include breakpoint($desktop) {
    display: inline-block;
    display: initial;
  }
}

%u-not-desktop,
.u-not-desktop {
  
  @include breakpoint($desktop) {
    display: none;
  }
}

.u-shift-right {
  margin-left: auto;
}
