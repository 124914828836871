.catalogsearch-result-index {

  .page-title-wrapper {
    width: 100%;
    padding: $size-8xl $size-m;
    text-align: center;
    background-color: $brand-quaternary;
    color: $white;
    
    h1.page-title {
      font-family: $fontHeading;
      font-size: $size-2xl;
      line-height: $size-4xl;
      font-weight: 400;
    }
  }

  .action.tocompare {
    display: none;
  }
}