.account-menu-header {
  display: none;
}

.account-nav {
  padding: $size-m;
  text-transform: uppercase;
  font-size: $size-s;
  line-height: $size-l;
  color: $brand-primary;
  padding-bottom: 0;
  @include breakpoint($desktop) {
    padding: 0;
  }
  
  .account-nav-title {
    border: 1px solid $grey-lighter;
    padding: $size-m;
    padding-right: 11px;
    display: grid;
    grid-template-columns: $size-xl 1fr $size-xl;
    grid-gap: $size-2xs;
    align-items: center;
    font-size: $size-m;
    line-height: 20px;
    font-weight: bolder;
    @include breakpoint($desktop) {
      display: none;
    }
  }
  .account-nav-content {
    height: 0;
    transition: height .3s ease-in-out;
    overflow: hidden;
    @include breakpoint($desktop) {
      height: auto;
    }
    &.active {
      height: 379px;
    }
    .nav.items {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
  .nav.item {
    list-style: none;
    a,
    strong {
      display: flex;
      align-items: center;
    }
    a {
      padding: 15px $size-xs;
      border: 1px solid $grey-lighter;
      border-top-width: 0;
      color: $brand-primary;
      @include breakpoint($desktop) {
        border-width: 1px 0 0 0;
        padding-left: $size-xs;
      }
    }
    a.signout-icon {
      border-bottom-width: 1px;
      @include breakpoint($desktop) {
        border-bottom-width: 0;
      }
    }
    &.current {
      padding: 15px $size-xs;
      border: 1px solid $grey-lighter;
      border-top-width: 0;
      background-color: $brand-vpale;
      
      @include breakpoint($desktop) {
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 1px;
        border-bottom-width: 0;
        background-color: unset;
      }
      a {
        padding: 0;
        border: 0;
      }
    }
    &:first-child {
      a,
      strong {
        border-top-width: 0;
        @include breakpoint($desktop) {
          border-top-width: 1px;
        }
      }
    }
  }
}

div[id="account-menu-mobile"] .block .title {
  &::after {
    height: $size-xl;
    width: $size-xl;
    content: " ";
    background: url("../images/icons/chevron-up.svg") no-repeat;
    background-size: $size-xl $size-xl;
    float: right;
    transition: all .3s;
    transform: rotate(180deg);
    justify-self: right;
    align-self: center;
  }
  strong {
    align-self: center;
    font-weight: $fontReg;
  }
}

div[id="account-menu-mobile"] .block .title.active::after {
  transform: rotate(0deg);
}

.icon-wrapper::before {
  margin-right: 8px;
  content: " ";
  height: $size-xl;
  width: $size-xl;
  display: block;
  background-size: $size-xl $size-xl;
}

.address-book-icon::before {
  background-image: url(../images/icons/icon-address-book.svg);
}

.my-account-icon::before {
  background-image: url(../images/icons/icon-account-purple.svg);
}

.account-information-icon::before {
  background-image: url(../images/icons/icon-account-information.svg);
}

.my-orders-icon::before {
  background-image: url(../images/icons/icon-orders.svg);
}

.marketing-preferences-icon::before,
.contact-preferences-icon:before {
  background-image: url(../images/icons/icon-contact.svg);
}

.wish-list-icon::before,
.wishlist-icon::before {
  background-image: url(../images/icons/icon-wishlist.svg);
  height: $size-l;
  width: $size-l;
  background-size: $size-l;
  margin-left: 3px;
  margin-right: $size-xs;
}

.signout-icon::before {
  background-image: url(../images/icons/icon-signout.svg);
}

.popup-authentication {
  .modal-header .modal-title {
    &::before {
      background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
  
  .block-authentication {
    margin: $size-m 0;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .block-new-customer {
    order: 2;
    margin-top: $size-6xl;
    border-top: 1px solid $grey-lighter;
    padding-top: $size-xl;
    
    .block-content {
      padding: 0 $size-xl;
      margin: $size-m 0;
      
      p {
        margin-bottom: 0;
      }
    }
  }
  
  .block-customer-login {
    order: 1;
    padding-top: $size-xl;
  }
  
  .forgotten-password {
    @include fontSize(14px);
    line-height: $size-l;
    text-decoration: underline;
    margin-top: -8px;
    color: $brand-primary;
  }
  
  .action-login,
  .action-register {
    @extend %secondary-button;
    width: 100%;
  }
  
  .block-title {
    padding-left: $size-xl;
    
    strong {
      font-weight: $fontBook;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $brand-primary;
    }
  }

  input {
    height: 40px;
    line-height: 40px;

    &::placeholder {
      line-height: 40px;
      vertical-align: middle;
    }
  }

  .mage-error {
    color: $error;
    font-size: $size-s;
    margin-bottom: $size-2xs;
  }

  [data-ui-id="checkout-cart-validationmessages-message-error"] {
    background: $error;
    color: $white;
    padding: 8px 0;
    text-align: center;
    display: block;
    width: calc(100% - 48px);
    margin-left: 24px;
  }
}

.modal-account-menu-header {
  .modal-header .modal-title {
    &::before {
      background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }

  // .modal-content {
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  .account-menu-header__list {
    list-style: none outside;
    padding-left: 0;
    
    a {
      @include modalListItem;
    }
  }
}
