.price-box,
.training-product-custom-price {
  @include fontSize(14px);
  line-height: $size-l;
  letter-spacing: 1px;

  .price-label {
    @extend %visually-hidden;
  }

  .old-price {
    text-decoration: line-through;
    margin-right: $size-3xs;
  }

  .special-price,
  .special-price-configurable {
    color: $brand-quinary;
    font-weight: $fontBold;
  }

  .configurable-price {

    .price-label {
      @extend %visually-unhidden;
    }
  }

  .plp-from-label {
    @extend %visually-hidden;
  }

}

.training-product-custom-price:after {
  content: '(ex VAT)';
  color: #2F1A45;
  font-size: 14px;
  font-weight: 300;
}

.pro-price {

  &--isTrained {
    display: none;
  }
}

.page-product-configurable {
  .product-info-main .product-info-price {
    .old-price {
      .price-wrapper {
        @include breakpoint($desktop) {
          font-size: 22px;
          margin-right: 5px;
        }
        &.price-including-tax::after,
        &.price-including-tax::before {
          display: none;
        }
      }
  }
  .price-box.price-final_price:has(.old-price) {
    display: flex;
  }
  }
}