//
//  COOKIE NOTICE
//

.message.cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: $brand-primary;
  padding: 24px;
  z-index: 999;
  text-align: center;

  p, a {
    color: $white;
  }

  a {
    text-decoration: underline;
  }

  button {
    background: $brand-quaternary;
  }
}