.kit-list-wrapper {
  flex-flow: row wrap;
  display: flex;
  margin-top: $size-xl;

   @include breakpoint($desktop) {
     justify-content: space-between;
     margin-top: 55px;
   }
  
  .kit-item {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: $size-m;
    
    @include breakpoint($desktop) {
      width: span(8);
      flex-basis: span(8);
      margin-bottom: 0;
    }

    &:nth-child(n+3) {
      @include breakpoint($desktop) {
        margin-top: $size-xl;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .kit-header {
    padding: $size-2xs 0;

    h5 {
      @include fontSize(19px);
      line-height: $size-2xl;
      letter-spacing: 2px;
      color: $white;
      text-transform: uppercase;
      margin: 0;
      text-align: center;
      font-weight: 300;
    }
  }

  .kit-body {
    background-color: $white;
  }

  .common-params {
    margin-top: 0;
    padding: $size-2xs $size-m 0;

    .key-value-pair {
      display: flex;
      flex-flow: row nowrap;
      color: $brand-primary;
      border-bottom: 1px solid $grey-lighter;
      
      &:last-child {
        margin-bottom: $size-2xs;
        border-bottom: 0;
      }

      &--kit_course_cost,
      &--kit_value_of_products {
        font-weight: 500;
      }
    }

    span {
      @include fontSize(14px);
      line-height: $size-l;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 7px 0;
    }

    .key {
      width: 60%;
      flex-basis: 60%;
      flex-grow: 1;
    }

    .value {
      text-align: right;
      padding-left: 24px;
    }
  }

  .view-kit-list {
    width: calc(100% - 32px);
    margin-left: $size-m;
    margin-bottom: $size-m;

    @include breakpoint($desktop) {
      text-align: left;
      position: relative;
    }

    &::after {
      
      @include breakpoint($desktop) {
        @include icon-svg-reset;
        width: 20px;
        height: 20px;
        background: url(../images/icons/icon-plus-white.svg) 50% 50% no-repeat;
        background-size: 16px;
        position: absolute;
        right: 16px;
        top: 10px;
      }
    }

    &--open::after {

      @include breakpoint($desktop) {
        background: url(../images/icons/icon-minus-white.svg) 50% 50% no-repeat;
      }
    }
  }
}

.no-touchevents .full-kit-list {
  max-height: 0;
  overflow: hidden;
  display: block;
  transition: max-height 800ms linear;
  width: 100%;

  &--show-desktop {
    max-height: 5000px; // need a number that is going to be larger than what will realistically be needed
    //display: table;
  }
}

.full-kit-list {

  .key-value-pair {
    display: flex;
    flex-flow: row nowrap;
    padding-left: $size-m;
    padding-right: $size-m;

    &:nth-child(odd) {

      .value,
      .key {
        background: $brand-vpale;
      }
    }

    &:nth-last-child(2) {

      @include breakpoint($desktop) {
        margin-bottom: $size-m;
      }
    }

    &.additional-header {
      // font-weight: $fontBold;
      margin-top: $size-m;
      margin-bottom: $size-2xs;
      color: $grey-dark;
      padding: 7px $size-m;
      background: $white;

      @include breakpoint($desktop) {
        margin-top: 0;
      }
      
      span {
        border-bottom: 1px solid $grey-lighter;
        padding: 0 $size-m 7px;
        background: $white !important;
      }
    }

    span {
      @include fontSize(14px);
      line-height: $size-l;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $brand-primary;
      flex-grow: 1;
    }
  }

  .value {
    text-align: right;
    padding: 7px $size-m 7px $size-xs;
    min-width: 52px;
  }

  .key {
    font-weight: $fontBold;
    padding: 7px $size-xs 7px $size-m;
  }

  .additional-header .key {
    font-weight: $fontReg;
  }

  .common-params--modal {

    @include breakpoint($desktop) {
      display: none;
    }
  }
}

[class*="modal-kit-list-"] {

  .modal-header {
    background: $brand-violet;
  
    .modal-title {
      
      &::before {
        display: none;
      }
    }
  }

  .common-params--modal {
    width: calc(100% - 32px);
    margin-left: $size-m;
    margin-top: $size-xl;
    margin-bottom: $size-6xl;
    padding: $size-2xs $size-m;

    .key-value-pair {
      padding: 0;

      &:last-child {

        span {
          border-bottom: 0;
        }
      }

      &--kit_course_cost span,
      &--kit_value_of_products span {
        font-weight: 500 !important;
      }
    }

    span {
      padding: 7px 0;
      border-bottom: 1px solid $grey-lighter;
      font-weight: $fontReg;
      background: $white !important;
    }
  }
}
