//
// Product Tabs - Description, Ingredients
//

@mixin tableHeader {
  color: $brand-primary;
  font-family: $fontBody;
  font-size: $size-m;
  font-weight: $fontBold;
  letter-spacing: 2px;
  line-height: $size-2xl;
  text-align: center;
}

.catalog-product-view .product-tabs__wrapper--tab a {
  text-decoration: underline;
}

.product-tabs__container,
.customer-services__tabs {
  
  @include breakpoint($desktop) {
    background-color: #FBF8FB;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: $size-7xl;
  }
}
  
.product-tabs__wrapper,
.cs-tabs-wrapper {
  
  @include breakpoint($desktop) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: $size-xl $size-xl 0;
    width: calc(100vw - 48px);
  }
  
  .tab-title {
    background-color: #FBF8FB;
    box-shadow: inset 0 1px 0 0 $grey-lighter;
    padding: $size-xl $size-4xl;
    position: relative;
    
    @include breakpoint(max-width $desktop) {
      &:nth-last-child(2) {
        box-shadow: inset 0 1px 0 0 $grey-lighter, inset 0 -1px 0 0 $grey-lighter;
      }
    }
    
    @include breakpoint($desktop) {
      padding: 0;
      margin-bottom: $size-xl;
    }
    
    @include breakpoint($desktop) {
      box-shadow: none;
      order: 0;
      margin-right: $size-4xl;
      
      &:last-of-type {
        margin-right: 0;
      }
    }
    
    a.title {
      color: $brand-primary;
      font-family: $fontBody;
      font-size: $size-m;
      font-weight: $fontReg;
      letter-spacing: 1px;
      line-height: 20px;
      text-transform: uppercase;
      margin: 0;
    }
    
    .icon {
      @include breakpoint($desktop) {
        display: none;
      }
      
      .icon--plus {
        background: url("../images/icons/icon-plus-2f1a45.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        opacity: 1;
        right: $size-2xl;
        top: 22px;
        position: absolute;
      }
      .icon--minus {
        background: url("../images/icons/icon-minus-2f1a45.svg");
        background-size: 20px 20px;
        height: 0;
        width: 20px;
        opacity: 0;
        right: $size-2xl;
        top: 22px;
        position: absolute;
      }
    }
    
    &.open {
      .icon--minus {
        opacity: 1;
        height: 20px;
      }
      .icon--plus {
        opacity: 0;
        height: 0;
      }
    }
    
    .vertical {
      position: absolute;
      background: $brand-primary;
      width: 1.67px;
      height: 13.33px;
      right: $size-4xl;
      top: 27px;
      transition: all .5s ease-in-out;
      transform: rotate(-90deg);
    }
    
    .horizontal {
      position: absolute;
      background: $brand-primary;
      width: 13.33px;
      height: 1.67px;
      top: $size-2xl;
      right: 34.2px;
      transition: all .5s ease-in-out;
      transform: rotate(-90deg);
      opacity: 1;
    }
    
    &.open {
      opacity: 1;
      
      @include breakpoint(max-width $desktop) {
        box-shadow:  inset 0 -1px 0 0 $grey-lighter, inset 0 1px 0 0 $grey-lighter;
      }
      
      &:focus {
        outline: none;
      }
      
      @include breakpoint($desktop) {
        border-bottom: 2px solid $brand-secondary;
        padding-bottom: $size-xl;
        margin-bottom: 0;
      }
      
      a.title {
        font-weight: $fontBold;
        
        //@include breakpoint(max-width $desktop) {
        //  animation: blinkAccordion .7s;
        //}
      }
      
      @include breakpoint(max-width $desktop) {
        @keyframes blinkAccordion {
          0% {
            color: $brand-primary;
          }
          49% {
            color: #fbf8fb;
          }
          100% {
            color: $brand-primary;
          }
        }
      }
      
      .vertical {
        transition: all .7s ease-in-out;
        transform: rotate(90deg);
      }
      
      .horizontal {
        transition: all .7s ease-in-out;
        transform: rotate(90deg);
        opacity: 0;
      }
    }
  }
  
  &--tab {
    position: relative;
    
    @include breakpoint($desktop) {
      order: 1;
      border-top: 1px solid $border-light;
      width: 100vw;
      padding: 0 16px;
      
      &.open {
        display: inline-block;
      }
    }
    
    .tab-content {

      .section-wrapper {
        
        @include breakpoint($desktop) {
          padding: 0 16px;
        }
      }
      
      //Trustpilot gives it one of the 3 IDs on the wrapper
      #trustpilot-widget-trustbox-0-wrapper,
      #trustpilot-widget-trustbox-1-wrapper,
      #trustpilot-widget-trustbox-2-wrapper {
        padding: $size-4xl $size-xl;
        
        @include breakpoint($smallMobile) {
          padding: $size-4xl;
        }
        
        @include breakpoint($desktop) {
          padding: 89px 0;
        }
      }
      
      .section--one,
      .section--two,
      .section--light,
      .section--dark,
      .section--split {
        padding: $size-4xl;
        
        @include breakpoint($desktop) {
          padding: $size-4xl $size-m;
        }
        
        @include breakpoint($desktop-mid) {
          padding: $size-4xl 0;
        }
        
        &.extra--padding {
          @include breakpoint($desktop) {
            padding: 89px 0;
          }
        }
        
        p.last--paragraph {
          margin: $size-2xl 0 0;
        }
      }
      
      ul {
        li {
          color: $grey-dark;
          font-size: $size-m;
          line-height: $size-xl;
        }
      }
      
      .main-title {
        @include fancyHeading;
        margin: 0;
        color: $brand-primary;
      }
      
      .sub-title {
        @include fancyHeading;
        margin: 0;
      }
      
      p {
        color: $grey-dark;
        font-size: $size-m;
        line-height: $size-xl;
        margin: $size-2xl 0 0;
      }
      
      ul {
        padding-left: 20px;
        margin-top: $size-xl;
        
      }
      
      iframe {
        @include breakpoint(max-width $desktop) {
          width: 100%;
          height: 330px;
        }
      }
      
      .section--one {
        @include breakpoint($desktop) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        &__left {
          margin-bottom: $size-2xl;
          
          @include breakpoint($desktop) {
            flex-basis: span(7);
            margin: 0;
          }

          &:only-child {
            @include breakpoint($desktop) {
              padding: $size-8xl 0;
              flex-basis: span(10);
            }
          }
        }
        
        &__right {
          @include breakpoint($desktop) {
            flex-basis: span(8);
            margin: 0;
          }

          img {
            max-width: 480px;
            margin-top: 40px;
            width: 100%;

            @include breakpoint($desktop) {
              display: block;
              margin: 0 auto; 
            }
          }
        }
      }
      
      .section--two,
      .section--light {
        background: $brand-vpale;
        
        .sub-title {
          color: $brand-primary;
        }
        
        p {
          color: $grey-dark;
        }
      }
      
      .section--two {
        background-color: $brand-vpale;
        
        @include breakpoint($desktop) {
          padding: 138px $size-m;
        }
        
        @include breakpoint($desktop-mid) {
          padding: 138px 0;
        }
      }
      
      .section--dark {
        background: #827CB2;
        
        .sub-title,
        p {
          color: $white;
        }
      }
      
      .section--dark,
      .section--light {
        
        @include breakpoint($desktop) {
          display: flex;
          justify-content: space-between;
        }
        
        &.constrained {
          @include breakpoint($desktop) {
            flex-basis: span(8);
            padding: $size-7xl 0;
            
            p,
            h4,
            div {
              max-width: 872px;
            }
          }
          
          .sub--content {
            p {
              @include breakpoint($desktop) {
                width: 44%;
                flex-basis: span(6);
              }
            }
          }
        }
        
        &.columns {
          @include breakpoint($desktop) {
            flex-direction: column;
          }
        }
        
        .row--step {
          
          @include breakpoint($desktop) {
            display: flex;
            justify-content: space-between;
          }
          
          &:first-of-type {
            margin-top: $size-4xl;
          }
          
          &__title {
            font-size: $size-m;
            color: $brand-primary;
            line-height: 20px;
            letter-spacing: 1px;
            font-weight: $fontBold;
            text-transform: uppercase;
            
            @include breakpoint($desktop) {
              display: inline-block;
              width: 36%;
              vertical-align: top;
            }
          }
          
          &__content {
            margin: $size-m 0 $size-2xl;
            padding-bottom: $size-m;
            border-bottom: 1px solid $border-light;
            
            @include breakpoint($desktop) {
              display: inline-block;
              width: 60%;
              margin-top: 0;
              margin-bottom: 20px;
              padding-bottom: 20px;
            }
          }
          
          &:last-of-type {
            p {
              border-bottom: 0;
              margin-bottom: 0;
            }
          }
        }
        
        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: $size-4xl;
          
          @include breakpoint($desktop) {
            max-width: 872px;
            margin-top: $size-7xl;
          }
          
          th {
            @include tableHeader;
            
            @include breakpoint($desktop) {
              text-align: left;
              padding-left: 70px;
            }
          }

          tr:first-child {
            th {
              font-size: 13px;
              letter-spacing: 0;

              @include breakpoint($tablet) {
                font-size: $size-m;
                letter-spacing: 2px;
              }
            }
          }
          
          .row--column {
            color: $brand-primary;
            font-family: $fontBody;
            font-size: $size-s;
            font-weight: $fontBold;
            letter-spacing: 2px;
            line-height: $size-2xl;
            padding-left: 10px;
            text-align: left;

            @include breakpoint($tablet) {
              font-size: $size-m;
            }
            
            @include breakpoint($desktop) {
              padding-left: 70px;
            }
          }
          
          td,
          th {
            border-bottom: 1px solid $border-light;
          }
          
          td {
            padding: 7px $size-m;
            font-size: $size-s;
            line-height: $size-l;
            height: $size-4xl;
            
            @include breakpoint($desktop) {
              padding-left: 70px;
              height: $size-2xl;
            }
          }
        }
        
        &__left {
          margin-bottom: $size-m;
          
          @include breakpoint($desktop) {
            display: inline-block;
            flex-basis: span(8);
            margin: 0 !important;
          }
        }
        
        .delivery {
          @include breakpoint($desktop) {
            flex-basis: span(9 of 12);
            
            .row--step {
              justify-content: flex-start;
            }
            
            .row--step__title {
              flex-basis: span(6);
            }
            
            .row--step__content {
              flex-basis: span(9);
            }
          }
        }
        
        &__right {
          @include breakpoint($desktop) {
            display: inline-block;
            flex-basis: span(7);
            margin: 0 !important;
          }
        }
      }
      
      .section--split {
        
        @include breakpoint($desktop) {
          display: flex;
          justify-content: space-between;
          padding: $size-7xl 0;
        }
        
        &__left,
        &__right {
          @include breakpoint($desktop) {
            margin: 0;
            padding: $size-7xl 0;
            flex-basis: span(7);
          }

          ul {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
            margin-top: $size-2xl;
          }
        }
        
        p {
          margin: $size-2xl 0 $size-xl;
        }
        
        ul {
          margin-bottom: $size-2xl;
          padding-bottom: $size-2xl;
          padding-left: $size-2xl;
          border-bottom: 1px solid $border-light;
          
          @include breakpoint($desktop) {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
        
        .split--divider {
          display: none;
          
          @include breakpoint($desktop) {
            display: flex;
            justify-content: center;
            flex-basis: span(2);
            
            div {
              height: 100%;
              border-left: 1px solid $border-light;
            }
          }
        }
      }
    }
    
    .features-tabs {
      margin-top: $size-2xl;
      
      &__tab {
        position: relative;
        border-bottom: 1px solid #E9E5EF;
        
        .tab-title {
          display: flex;
          padding: 0 0 $size-3xs 0;
          position: relative;
          box-shadow: none;
          background: none;
          margin: 0;
          
          &.open {
            border-bottom: 0;
          }
          
          img {
            width: $size-m;
            height: $size-m;
            margin-top: $size-2xs;
          }
          
          .title {
            width: auto;
            display: inline-block;
            margin: 0;
            padding: 0 $size-2xl;
            color: $brand-secondary;
            font-family: $fontBody;
            font-size: $size-m;
            font-weight: $fontBold;
            letter-spacing: 1px;
            line-height: $size-2xl;
            text-transform: uppercase;
          }
          
          .ingredient {
            height: $size-xl;
            width: $size-xl;
          }
          
          .icon {
            display: block;
          }
          
          .vertical {
            top: 7px;
            right: 5px;
            background: $brand-symbol;
            
            @include breakpoint($desktop) {
              right: 20px;
            }
          }
          
          .horizontal {
            top: $size-xs;
            right: 0;
            background: $brand-symbol;
            
            @include breakpoint($desktop) {
              right: 15px;
            }
          }

          .icon--plus {
            background: url("../images/icons/icon-plus-827cb2.svg");
            background-size: 20px 20px;
            height: 20px;
            width: 20px;
            opacity: 1;
            right: 0;
            top: 5px;
            position: absolute;
          }
          .icon--minus {
            background: url("../images/icons/icon-minus-827cb2.svg");
            background-size: 20px 20px;
            width: 20px;
            opacity: 0;
            height: 0;
            right: 0;
            top: 5px;
            position: absolute;
          }
          
          &.open {
            .icon--minus {
              opacity: 1;
              height: 20px;
            }
            .icon--plus {
              opacity: 0;
              height: 0;
            }
          }
          
          
        }
        
        
        .tab-content {
          display: none;
          
          p {
            padding-left: $size-6xl;
            padding-right: $size-2xl;
            padding-bottom: $size-xl;
            margin: 0;
            font-size: $size-m;
            
            @include breakpoint($desktop) {
              padding-left: $size-6xl;
            }
          }
        }
        
        &:not(:first-child) {
          padding-top: 6px;
        }
      }
    }
  }
}

.cs-tabs-wrapper {
  padding: $size-xl 0 0;
  width: 100vw;
}

#kit_options {
  .section--light.columns {

    @include breakpoint($desktop) {
      flex-direction: row;
    }

    .column {
      @include breakpoint($desktop) {
        width: 50%;
        display: inline-block;
      }

      &:first-of-type {
        @include breakpoint($desktop) {
          padding-right: 2%;
        }
      }

      &:last-of-type {
        margin-top: 20px;

        @include breakpoint($desktop) {
          padding-left: 2%;
          border-left: 1px solid;
          border-top: 0;
          margin-top: 0;
        }
      }
    }
  }
}
