.filter-and-sort {
  width: 100%;
  margin-bottom: $size-m;
  display: flex;
  flex-flow: row nowrap;

  &__button {
    @include fontSize(14px);
    line-height: $size-xl;
    padding: $size-m 0;
    display: block;
    width: 50%;
    flex-basis: 50%;
    box-shadow: inset 0 -1px 0 0 $grey-lighter;
    font-weight: $fontBold;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    height: 56px;

    &:hover {
      cursor: pointer;
    }

    &::before {
      @include icon-svg-reset;
      background: url(../images/icons/icon-filter.svg) 50% 50% no-repeat;
      background-size: $size-xl;
      margin-right: $size-2xs;
      width: $size-xl;
      height: $size-xl;
    }

    span {
      display: inline-block;
    }

    &--sort {
      box-shadow: inset 0 -1px 0 0 $grey-lighter, inset 1px 0 0 0 $grey-lighter;

      &::before {
        background: url(../images/icons/icon-sort.svg) 50% 50% no-repeat;
        background-size: $size-xl;
      }
    }
  }
}

.toolbar-products {
  // display: none;

  .toolbar-sorter,
  .toolbar-amount {
    display: none;
  }
}

.modal-sort-by {

  .modal-title::before {
    background: url(../images/icons/icon-sort-white.svg) 50% 50% no-repeat;
    background-size: $size-xl;
  }

  .toolbar-for-modal {
    list-style: none outside;
    padding-left: 0;

    li {
      @include modalListItem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.modal-filter-by {
  overflow-x: hidden !important;

  .modal-title::before {
    background: url(../images/icons/icon-filter-white.svg) 50% 50% no-repeat;
    background-size: $size-xl;
  }

  .wp-instant-search-wrapper,
  .filter-title,
  .filter-subtitle {
    display: none;
  }

  .filter-options-item {
    @include modalListItem;
    background: $brand-vpale;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    &::after {
      @include icon-svg-reset;
      width: 20px;
      height: 20px;
      background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
      background-size: 20px;
      position: absolute;
      top: $size-l;
      right: 30px;
    }

    &.active {
      background: $brand-secondary;
      color: $white;
      overflow-y: visible;

      &::after {
        background: none;
      }
    }
  }

  .filter-options-content {
    z-index: 10; // starter for 10... likely to go higher.
    background-color: $white;
    width: 100%;
    position: absolute;
    top: $size-7xl;
    left: 0;
    transform: translateX(100%);
    transform: translate3d(100%, 0, 0);
    transition: 600ms transform ease;
    display: block !important;
    min-height: 100vh;
    padding-bottom: 48px; // to accommodate sticky button

    .items {
      list-style: none outside;
      padding-left: 0;

      .item {

        a {
          @include modalListItem;
          font-weight: $fontReg;
        }

        .count {
          @include fontSize(12px);
          margin-left: $size-3xs;
        }

        &.wp-ln-selected {
          background-color: $brand-vpale;
          position: relative;

          &::after {
            @include icon-svg-reset;
            width: 20px;
            height: 20px;
            background: url(../images/icons/icon-check-small.svg) 50% 50% no-repeat;
            background-size: 20px;
            position: absolute;
            top: $size-l;
            right: 30px;
          }
        }
      }
    }

    .subfilter-header {
      list-style: none outside;
      padding-left: 0;
      margin-bottom: 0;

      li {
        @include modalListItem;

        &.subfilter-header__title {
          background-color: $brand-secondary;
          color: $white;
        }
      }
    }

    li.back-to-filters {
      padding-left: 56px;

      &:hover {
        cursor: pointer;
      }

      &::before {
        @include icon-svg-reset;
        width: 20px;
        height: 20px;
        background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
        background-size: 20px;
        position: absolute;
        top: $size-l;
        left: 26px;
        transform: rotate(180deg);
      }
    }
  }

  .active .filter-options-content {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1100; // to trump amasty label at 995 and sidebar at 1000
  }
}

.filter-current {
  display: flex;
  flex-flow: row nowrap;
  padding: $size-m $size-2xl $size-2xs;
  box-shadow: inset 0 -1px 0 0 $grey-lighter;

  .filter-current-subtitle {
    @extend %visually-hidden;
  }

  .items {
    list-style: none outside;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 1;
  }

  .item {
    @include fontSize(14px);
    line-height: $size-l;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $grey-dark;
    background-color: $grey-lightest;
    margin-right: $size-2xs;
    margin-bottom: $size-2xs;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-left: 10px;
  }

  .action.remove {
    background: transparent;
    width: 30px;
    line-height: $size-2xl;
    padding: 0;

    &::before {
      @include icon-svg-reset;
      width: $size-xs;
      height: $size-xs;
      background: url(../images/icons/icon-close-grey.svg) 50% 50% no-repeat;
      background-size: $size-xs;
      margin-top: -4px;
    }

    span {
      @extend %visually-hidden;
    }
  }

  .filter-actions {
    width: 97px;
    flex-basis: 97px;
    flex-shrink: 0;
    margin-left: auto;

    a {
      @extend %ghost-button-inverted;
      @include fontSize(14px);
      line-height: $size-l;
      padding: 7px 0; // magic numbers from design
      width: 100%;
    }
  }
}

.filter-state-placeholder {
  display: none !important;
}

.filter-state-placeholder .filter-current {
  padding: 0 $size-m $size-2xs;
  box-shadow: none;

  @include breakpoint($desktop) {
    max-width: 1168px;
    margin: 0 auto;
  }

  @include breakpoint(1232px) {
    padding-left: 0;
    padding-right: 0;
  }

  .filter-actions {
    order: 1;
    margin-left: 0;
  }

  .items {
    order: 2;
    margin-left: $size-2xs;
  }
}

.filters-sticky-apply {
  @include fontSize(14px);
  line-height: $size-6xl;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  font-weight: $fontBold;
  width: 100%;
  height: $size-6xl;
  background-color: $brand-secondary;
  color: $white;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;

  &:hover {
    cursor: pointer;
  }

  @include breakpoint($tablet) {
    width: 480px;
  }
}
