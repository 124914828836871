.page-wrapper.no-title {
  padding-top: 100px;
  @include breakpoint($tablet) {
    padding-top: 150px;
  }
}

section.row.template__header {
  padding: 30px;
  @include breakpoint($tablet) {
    max-width: 830px;
    margin: auto;
  }

  .header__col-wrapper {
    h1 {
      font-family: $fontHeading;
      font-size: 37px;
      line-height: 38px;
      @include breakpoint($tablet) {
        font-size: 66px;
        line-height: 76px;
        text-align: center;
      }
    }
  }

}

//Content Type Header
section.ct-group.row.template__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: unset;
  padding: 0;
  margin: 0;
  @include breakpoint($desktop) {
    max-width: unset;
    flex-direction: row;
  }

  .header__col-text {
    position: relative;
    width: 90%;
    color: #fff;
    padding: 30px;
    margin: -20px auto 0;
    z-index: 1;
    @include breakpoint($desktop) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5%;
      margin-top: 0;
    }

    h1 {
      font-family: lust-display-didone, serif;
      font-size: 37px;
      color: #fff;
      text-transform: capitalize;
      line-height: 38px;
      margin-top: 0;
      margin-bottom: 20px;
    }
    p {
      color: #fff;
    }
    .btn.btn-header-cta {
      width: 100%;
      @include breakpoint($desktop) {
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

}
//Content Header Custom Colours
.header-purple {

  section.ct-group.row.template__header {
    @include breakpoint($desktop) {
      background-color: $brand-primary;
    }

    .header__col-text {
      background-color: $brand-primary;

      @include breakpoint($desktop) {
        background-color: transparent;
      }
    }

    .btn.btn-header-cta {
      background-color: $brand-senary;
    }

  }

}
.header-indigo {

  section.ct-group.row.template__header {
    @include breakpoint($desktop) {
      background-color: $brand-secondary;
    }

    .header__col-text {
      background-color: $brand-secondary;

      @include breakpoint($desktop) {
        background-color: transparent;
      }
    }

    .btn.btn-header-cta {
      background-color: $brand-senary;
    }

  }

}
.header-violet {

  section.ct-group.row.template__header {
    @include breakpoint($desktop) {
      background-color: $brand-quaternary;
    }

    .header__col-text {
      background-color: $brand-quaternary;

      @include breakpoint($desktop) {
        background-color: transparent;
      }
    }

  }

}
.header-lilac {

  section.ct-group.row.template__header {
    @include breakpoint($desktop) {
      background-color: $header-lilac;
    }

    .header__col-text {
      background-color: $header-lilac;

      @include breakpoint($desktop) {
        background-color: transparent;
      }

      h1,p {
        color: #000;
      }

    }

  }

}
.header-purple-tertiary {

  section.ct-group.row.template__header {
    @include breakpoint($desktop) {
      background-color: $brand-tertiary;
    }

    .header__col-text {
      background-color: $brand-tertiary;

      @include breakpoint($desktop) {
        background-color: transparent;
      }

      h1,p {
        color: #000;
      }

    }

  }

}

//Content Type 3 Column Blocks
section.ct-group.row.template__info.cta {
  margin: 0;

  .flex__wrapper {
    max-width: 1750px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($tablet) {
      display: flex;
      justify-content: space-between;
      padding: 0 5% 5% 5%;

      .ct-view-oss_cta_images {
        margin-right: 2%;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    @include breakpoint($desktop) {
      padding: 0 130px 50px 130px;
    }

    .cta__wrap-desc {
      padding: 0 5% 5% 5%;

      h3 {
        font-size: 1.357em;
        font-weight: $fontBold;
        margin-bottom: 20px;

        @include breakpoint($tablet) {
          min-height: 52px; 
        }
        
        @include breakpoint($desktop-large) {
          min-height: unset;
        }
      }

      @include breakpoint($tablet) {
        padding: 0 15px 0 0;

        .btn.btn-purple-cta {
          width: auto;
        }
      }

    }

  }

}

section.row.template__info.cta.two-col {

  max-width: 1168px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto -30px auto;
  @include breakpoint($tablet) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: -60px;
  }


  .ct-view-profile_image_copy {
    flex-basis: 49%;
    margin-bottom: 35px;

    @include breakpoint($tablet) {
      flex-basis: 31.333%;
      margin-right: 2%;
      margin-bottom: 60px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    h3 {
      font-weight: $fontBold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .cta__wrap-desc {

      p:first-of-type {
        min-height: 62px;
      }

      .btn.btn--primary {
        width: 100%;
        @include breakpoint($tablet) {
          width: auto;
          color: $white;
          text-decoration: none;
          padding-left: 46px;
          padding-right: 46px;
        }
      }

    }

  }

}

section.row.template__header.editor {
  padding: 0;
  margin-top: -18px;
  margin-bottom: 40px;

  @include breakpoint($desktop) {
    display: flex;
    max-width: 1168px;
    margin-top: 90px;
    margin-bottom: 60px;
  }

  .header__col-wrapper.left-image {
    img {
      width: 100%;
    }
  }

  .header__col-text-editor {
    flex-basis: 70%;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint($desktop) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @include breakpoint($desktop-mid) {
      padding-right: 0;
    }

    h1 {
      font-size: $size-xl;
      font-weight: $fontBold;
      color: $brand-primary;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      text-align: left;
      margin-bottom: 15px;
      @include breakpoint($tablet) {
        font-size: $size-2xl;
      }
    }
    h3 {
      font-size: $size-l;
      font-weight: $fontBold;
      color: $brand-primary;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    hr {
      border: 0;
      height: 1px;
      width: 100%;
      color: $grey-lighter;
      background-color: $grey-lighter;
      margin-bottom: 12px;
      margin-left: 0;
      @include breakpoint($tablet) {
        margin-bottom: 18px;
      }
    }

  }

  .readmore {
    @include breakpoint($tablet) {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    &.expanded {
      @include breakpoint($tablet) {
        -webkit-line-clamp: 9999;
      }
    }
  }

  .readmore-link.action {
    @include breakpoint($tablet) {
      display: block;
    }
  }

}

section.row.template__carousel.products {

  h2 {
    font-size: $size-xl;
    font-weight: $fontBold;
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
  }

  .block.widget.block-products-list.grid {

    .products-grid {
      padding-left: 12px;
      padding-right: 12px;
      @include breakpoint($tablet) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    ol.product-items.widget-product-grid {

      .product-item-name {
        min-height: 75px;
        @include breakpoint($tablet) {
          min-height: 55px;
        }
      }

      .price-box {

        .old-price {
          display: block;
          height: 0;
        }

        .price-wrapper.price-including-tax {
          display: none;
          height: 0;
        }

      }

    }

  }

  .action.tocompare,
  .action.towishlist,
  .actions-primary {
    display: none;
  }

  .product-item:hover .product-item-actions .actions-primary {
    @include breakpoint($tablet) {
      display: block;
    }

    form {
      background-color: transparent;
    }

  }

  //slider styling
  .slick-list {
    width: 100%;
  }

  .slick-slider .slick-track {
    display: flex;

    .slick-slide {
      margin-left: 1%;
      margin-right: 1%;
      .product-item {
        max-width: unset;
      }
    }

  }

  .slick-dots {
    bottom: -10px;

    li {
      cursor: pointer;
      height: 4px;
      width: 30px;
      margin: 0 2px;
      background-color: #bbb;
      display: inline-block;
      transition: background-color .6s ease;

      &.slick-active {
        background-color: #717171;
      }

      button:before {
        display: none;
      }

    }

  }


}

.background-purple-mid {
  color: $white;
  background-color: #48376E;
}

//Trainers pages
.readmore {
  display: block;
  display: -webkit-box;
  max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  @include breakpoint($tablet) {
    line-height: 26px;
    -webkit-line-clamp: unset;
  }
}

.readmore.expanded {
  height: auto;
    -webkit-line-clamp: 9999;
}

.readmore-link.action {
  font-size: 16px;
  font-weight: $fontBook;
  color: $white;
  background-color: transparent;
  border-radius: 0;
  display: unset;
  text-align: left;
  text-transform: none;
  padding: 0;
  @include breakpoint($tablet) {
    display: none;
  }
}

.ct-view-profile_image_copy_section h2 {
  font-family: $fontHeading;
  font-size: 30px;
  color: $brand-primary;
  text-align: center;
  margin-bottom: 14px;
  @include breakpoint($tablet) {
    font-size: 40px;
    margin-bottom: 26px;
  }
}