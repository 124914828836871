.booking-training-popup {

  .modal-title::before {
    background: url(../images/icons/icon-info.svg) 50% 50% no-repeat;
    background-size: 16px;
  }
  
  &.modal-popup._show {

    .terms-and-conditions-wrapper{
      margin-bottom: 20px;
      label{
        margin-bottom: 10px;
      }
    }
    
    .product-options-wrapper {
      
      .field {
        display: block;
        width: 100%;

        label:after {
          display: none;
        }

        &.text {

          label {
            @extend %visually-hidden;
          }
        }

        &.select-input {

          label {
            @include fontSize(16px);
            line-height: 20px;
            text-transform: none;
            font-weight: 300;
            letter-spacing: 0;
          }
        }

        &[data-title*="Terms"] {
          margin-top: 32px;
          margin-bottom: 24px;

          & > label {
            display: none;
          }

          label {
            background-color: $white !important;
          }

          .mage-error {
            margin-top: 0;
          }
        }

        .checkbox {
          margin-bottom: 0;
        }
      }

      h2 {
        @include fontSize(24px);
        line-height: 32px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $brand-primary;
        margin: 0 0 16px;
        font-weight: 300;
      }

      p {
        margin-bottom: 16px;
      }

      .attendee-permanent-notice {
        padding: 24px;
        background-color: #F2F1F7;
        color: $brand-primary;
        margin-top: 16px;

        h3 {
          @include fontSize(16px);
          line-height: 20px;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $brand-primary;
          margin: 0 0 8px;
          font-weight: 500;
        }

        p {
          line-height: 24px;
        }
      }

      .mage-error {
        @include fontSize(12px);
        line-height: 16px;
        color: $error;
        margin: -8px 0 16px;
      }
    }

    .product-options-bottom {
      
      div.actions {
        position: static;
      }
    }

    // I think these should look different if in fact we actually need two
    #booking-available-notice-please-phone,
    #booking-not-available {
      background: #F7F2F8;
      padding: 24px;
      color: #85517E;
      width: 100%;
      margin: 0 0 16px;
    }

    .product-info-price {
      display: none;
    }

    .field.qty { 
      display: none;
    }

    .modal-content {
      padding: 0 40px;
    }
  }

  .actions {
    max-width: 100% !important;
    margin-bottom: 32px;
  }

  .training-add-to-cart {
    width: 100%;
    background-color: $brand-senary !important;
    height: 40px !important;
    
    &.disabled {
      background-color: $grey-lightest !important;
      color: $grey-dark !important;
    }
  }
  
  .product-not-available-message {
    background: #8c69b1;
    color: #fff;
    font-size: $size-l;
    padding: 20px;
  }
}
