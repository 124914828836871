//
// Found on Cart, Checkout
//

.form-cart {
  margin-top: $size-2xl;
  background: $white;
  border-top: 1px solid $border-light;
  padding: $size-xl 0;

  @include breakpoint($desktop) {
    margin: 0;
  }

  .cart.items {
    width: 100%;

    .cart.item {
      padding-bottom: $size-xl;
      margin-bottom: $size-xl;
      border-bottom: 1px solid $border-lighter;

      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }

      .item-info {
        padding: 0 $size-xl;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;

        @include breakpoint(max-width $smallMobile) {
          padding: 0; //fix for small mobiles
        }
      }
    }

    .table-head {
      display: none;
    }

    .item-info {
      .col.item {
        position: relative;
        vertical-align: top;
        height: auto;
        width: 15%;
        display: inline-block;

        @include breakpoint($desktop) {
          width: 8%;
        }
      }

      .product-info {
        padding-left: $size-xs;
        position: relative;
        display: inline-block;
        width: 84%;
        margin-bottom: -20px;

        @include breakpoint($desktop) {
          width: 91%;
        }

        .product-item-details {
          max-width: calc(100% - 132px); // width of Qty box is 120px. Add 12px for breathing space
        }

        strong {
          a {
            @include subHeading;
          }
        }

        .item-options {
          margin: $size-2xs 0 0;

          dt,
          dd {
            display: inline-block;
            color: $grey-dark;
            font-size: $size-s;
            margin: 0;
          }

          dt {
            padding-right: $size-3xs;
          }
        }

        .control.qty {
          position: absolute;
          top: $size-3xs;
          right: 0;
          display: flex;

          .qty-box {
            font-size: $size-xl;

            &:first-of-type {
              border-radius: 2px 0 0 2px;
              line-height: $size-2xl;

              @include breakpoint($desktop) {
                line-height: $size-3xl;
              }
            }
          }

          .qty-box,
          .input-text {
            @include qtyBox;
          }

          .input-text {
            padding: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
          }
        }
      }

      .item-actions {
        display: block;
        width: 100%;
        text-align: right;
        z-index: 9;

        @include breakpoint($mobile $desktop) {
          position: absolute;
          right: $size-xl;
          top: 53px;
          width: auto;
        }

        a {
          background: none;
          padding: 0;
          font-weight: $fontReg;

          &.action-edit {
            padding-right: $size-xs;
          }

          &.towishlist {
            display: none;
          }

          span {
            color: $brand-primary;
            text-transform: uppercase;
            text-decoration: underline;
            font-size: $size-s;
          }
        }
      }
    }

    .cart.item.message.notice {
      padding: 10px 15px 0 0;

      @include breakpoint($desktop) {
        padding-right: 25px;
      }

    }

  }
}

.product-info,
.product-item-inner {
  .product-item-name {
    color: $brand-primary;
    text-transform: uppercase;
    font-size: $size-s;
    font-weight: $fontBold;
    letter-spacing: 1px;
    line-height: $size-l;

    a {
      color: $brand-primary;
    }
  }

  .item-options {
    margin: $size-2xs 0 0;

    dt,
    dd {
      display: inline-block;
      color: $grey-dark;
      font-size: $size-s;
      margin: 0;
      text-transform: none;
    }

    dt {
      padding-right: $size-3xs;
    }
  }

  .subtotal {
    display: inline-block;
    margin-top: $size-2xs;

    .price {
      color: $brand-primary;
      font-size: $size-s;
      font-weight: $fontBold;
    }
  }
}

.product-image-wrapper {
  padding-bottom: 0 !important;
}

span.price-wrapper.price-excluding-tax {
    display: none;
}

element.style {
}
.product-item-pricing span.price-wrapper.price-excluding-tax {
    display: block !important;
}

.product-info-price span.price-wrapper.price-excluding-tax {
    display: block !important;
}

.price-container.price-final_price span.price-wrapper.price-excluding-tax {
    display: block !important;
}


.modal-content {
    border: none !important;
    box-shadow: none !important;
}

button.action-close {
    background-color: #2F1A45 !important;
    /* display: none; */
    border: none;
}

.cms-index-index .modal-header {
    padding: 0px !important;
}
