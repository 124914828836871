.footer-menu {
  box-shadow: inset 0 1px 0 0 $grey-lighter;

  @include breakpoint($desktop) {
    width: 100%;
    padding-top: $size-2xl;
    padding-bottom: 16px;
    box-shadow: none;
  }

  &__list {
    list-style: none outside;
    padding-left: 0;

    @include breakpoint($desktop) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;
    }


    a {
      @include fontSize(16px);
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $brand-primary;
      padding: $size-l $size-2xl;
      display: block;
      font-weight: $fontBold;
      position: relative;
      box-shadow: inset 0 -1px 0 0 $grey-lighter;

      @include breakpoint($desktop) {
        @include fontSize(14px);
        line-height: $size-l;
        padding: 0;
        box-shadow: none;
        margin-bottom: $size-m;
      }
    }
  }

  &__item {

    @include breakpoint($desktop) {
      width: span(3 of 14);
      flex-basis: span(3 of 14);
      margin-right: gutter();
    }
  }

  &__inner-list--level1 {
    overflow: hidden;
    height: 0;
    transition: height 600ms ease;
    list-style: none outside;
    padding-left: 0;
    background-color: $brand-vpale;

    @include breakpoint($desktop) {
      height: auto !important;
      background-color: $white;
    }
  }

  &__inner-item {

    a {

      @include breakpoint($desktop) {
        font-weight: $fontReg;
        margin-bottom: $size-xs;
      }
    }
  }

  .inner-list-header {
    display: none;
  }

  &__icon {
    display: block;
    position: absolute;
    right: 20px;
    top: $size-xs;
    height: $size-2xl;
    width: $size-2xl;
    background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
    background-size: 20px;

    @include breakpoint($desktop) {
      display: none;
    }
  }

  &__item--parent.show-footer-menu {

    .footer-menu__icon {
      background: url(../images/icons/icon-chevron-down-dark.svg) 50% 50% no-repeat;
      background-size: 20px;
    }
  }
}

.block-minicart strong.subtitle {
    display: none !important;
}

.amlabel-text {
    position: static !important;
}

.wp-filters.wp-filters-ajax {
    display: none;
}

.checkout-klarna-index tr.totals.sub.incl {
    display: none;
}

.checkout-cart-index tr.totals.sub.incl {
    display: none;
}

/*span.price-wrapper.price-excluding-tax {
    display: none;
}*/
