//
// add mixins in here that can be used globally
//

// Convert the px values in the design to rems
@mixin fontSize ($pix) {
  $remVal: $pix / 16px;
  
  font-size: $pix;
  font-size: $remVal + rem;
}

// SVG Icon reset mixin
@mixin icon-svg-reset {
  content: "";
  width: $size-2xl;
  height: $size-2xl;
  display: inline-block;
  vertical-align: middle;
}

@mixin modalListItem {
  @include fontSize(16px);
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $brand-primary;
  padding: $size-l $size-2xl;
  display: block;
  font-weight: $fontBold;
  position: relative;
  box-shadow: inset 0 -1px 0 0 $grey-lighter;
}

@mixin mainHeading {
  color: $brand-primary;
  font-family: $fontBody;
  font-size: $size-xl;
  font-weight: $fontBold;
  letter-spacing: 2px;
  line-height: $size-2xl;
}

@mixin subHeading {
  color: $brand-primary;
  font-family: $fontBody;
  text-transform: uppercase;
  font-size: $size-s;
  font-weight: $fontBold;
  letter-spacing: 1px;
  line-height: 20px;
}

@mixin largeHeading {
  color: $brand-primary;
  font-family: $fontBody;
  font-size: $size-xl;
  font-weight: $fontReg;
  letter-spacing: 2px;
  line-height: $size-2xl;
}

@mixin fancyHeading {
  color: $brand-primary;
  font-family: $fontHeading;
  font-size: $size-2xl;
  line-height: $size-4xl;

  @include breakpoint($desktop) {
    font-size: $size-4xl;
    line-height: $size-6xl;
  }
}

@mixin qtyBox {
  height: $size-4xl;
  width: $size-4xl;
  min-width: $size-4xl;
  text-align: center;
  vertical-align: middle;
  border: 1px solid $grey-lighter;
  border-radius: 0 2px 2px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;

  .icon--minus {
    background: url("../images/icons/icon-minus.svg");
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
  }

  .icon--plus {
    background: url("../images/icons/icon-greyplus.svg");
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
  }

  @include breakpoint($desktop) {
    height: $size-6xl;
    width: $size-6xl;
  }
}

@mixin wishlist {
  padding: 0;
  position: absolute;
  top: $size-m;
  right: $size-m;
  height: $size-m;
  width: $size-l;
}