.main-menu {
  z-index: 40; // to be higher than the pro plp sign in button that has to be higher than the amasty stuff
  background-color: $white;
  width: 100%;
  position: fixed;
  top: 96px;
  left: -100%;
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
  transition: 600ms transform ease;
  
  @include breakpoint($desktop) {
    position: static;
    width: 100%;
    flex-basis: span(16);
    background-color: $brand-primary;
    padding-top: $size-xl;
    height: $size-8xl;
    transition: height 600ms ease;
  }

  @at-root .show-nav & {
    transform: translateX(100%);
    transform: translate3d(100%, 0, 0);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1100; // to trump amasty label at 995 and sidebar at 1000
  }

  &.desktop-mainmenu-visible {
    height: $size-8xl;
    overflow: visible;
  }

  &.desktop-mainmenu-hidden {
    height: 0 !important; // need to override inline style added by JS... which is also needed for cross breakpoint width changes
    overflow: hidden;
  }

  &__list {
    padding: 0;
    list-style: none outside;

    @include breakpoint($desktop) {
      margin: 0;
      padding: 0 span(2.5 wide);
      color: $white;
      display: flex;
      flex-flow: row nowrap;
      // justify-content: space-between;
    }

    > li {

      > a {
  
        @include breakpoint($desktop) {
          text-align: center;
          padding-left: $size-2xs;
          padding-right: $size-2xs;
        }
      }
    }
  }

  li {
    
    @include breakpoint(max-width 1024px) {
      padding-left: $size-2xs;
      box-shadow: inset 0 -1px 0 0 $grey-lighter;
      background-color: $brand-vpale;
      flex-basis: 100%;
    }

    @include breakpoint($desktop) {
      flex-grow: 1;
    }

    a {
      @include fontSize(16px);
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $brand-primary;
      padding: $size-l $size-2xl $size-l $size-xl;
      display: block;
      font-weight: $fontBold;
      position: relative;

      @include breakpoint($desktop) {
        font-weight: $fontReg;
      }
    }

    .main-menu__link {

      @include breakpoint($desktop) {
        color: $white;
        display: block;
        padding: $size-l 0;
    
        &:hover,
        &:visited,
        &:focus,
        &:active {
          color: $white;
          box-shadow: inset 0 -2px 0 0 $white;

          &:hover {
            color: $white !important;
          }
        }
      }
    }

    .main-menu__icon {
      display: block;
      position: absolute;
      right: 20px;
      top: $size-xs;
      height: $size-2xl;
      width: $size-2xl;
      background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
      background-size: 20px;

      @include breakpoint($desktop) {
        display: none;
      }
    }

    .main-menu__icon--back {
      display: block;
      position: absolute;
      left: $size-l;
      top: $size-xs;
      height: $size-2xl;
      width: $size-2xl;
      background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
      background-size: 20px;
      transform: rotate(180deg);

      @include breakpoint($desktop) {
        display: none;
      }
    }

    &.main-menu__inner-item {

      a {
        font-weight: $fontReg;
      }
    }

    &.main-menu__inner-item--all {
      color: $white;
      background-color: $brand-quinary;

      a {
        color: $white;
        font-weight: $fontBold !important;

        &:hover,
        &:visited,
        &:active,
        &:focus {
          color: $white;
        }
      }
    }

    &.main-menu__item {

      @at-root .show-nav & {

        .main-menu__inner-list--level1 {

          @include breakpoint(max-width 1024px) {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
          }
        }
      }
    }

    &.main-menu__item--pro {
      // box-shadow: inset 8px 0 0 0 $brand-secondary;

      @include breakpoint($desktop) {
        box-shadow: none;
      }

      .main-menu__link {
        box-shadow: inset 0 -1px 0 0 $grey-lighter;

        @include breakpoint($desktop) {
          box-shadow: none;

          &:hover,
          &:visited,
          &:focus,
          &:active {
            box-shadow: inset 0 -2px 0 0 $white;
          }
        }
      }

      .main-menu__inner-list--level1 {

        @include breakpoint($desktop) {
          padding-bottom: $size-9xl;
        }
      }

      .main-menu__inner-item--all {
        color: $white;
        background-color: $brand-secondary;

        a {
          color: $white;

          &:hover,
          &:visited,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }
    }

    &.main-menu__item--shop {
      // box-shadow: inset 8px 0 0 0 $brand-quaternary;

      @include breakpoint($desktop) {
        box-shadow: none;
      }

      .main-menu__link {
        box-shadow: inset 0 -1px 0 0 $grey-lighter;

        @include breakpoint($desktop) {
          box-shadow: none;

          &:hover,
          &:visited,
          &:focus,
          &:active {
            box-shadow: inset 0 -2px 0 0 $white;
          }
        }
      }

      .main-menu__inner-item--all {
        color: $white;
        background-color: $brand-quaternary;

        a {
          color: $white;

          &:hover,
          &:visited,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }

      .main-menu__inner-item--cms_block {

        div {
          background: rgba(111, 75, 153, .8);

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &.main-menu__item--treatments {

      .main-menu__inner-item--all {
        background-color: $brand-quaternary;

        @include breakpoint($desktop) {
          display: none;
        }
      }
    }

    &.main-menu__item--training {

      .main-menu__inner-item--all {
        // color: $white;
        background-color: $brand-secondary;
      }
    }
    
    &.main-menu__item--blog {
      // background-color: $white;

      @include breakpoint($desktop) {
        background-color: transparent;
      }
    }

    &.main-menu__item--wishlist {
      background-color: $white;

      .main-menu__link::after {
        
        @include breakpoint(max-width 1024px) {
          @include icon-svg-reset;
          background: url(../images/icons/icon-heart-outlined-purple.svg) 50% 50% no-repeat;
          background-size: 16px;
          position: absolute;
          top: $size-xs;
          right: 20px;
        }
      }
    }

    &.country-swapper-menu {
      background-color: $white;

      .main-menu__icon {
        background: url(../images/icons/icon-globe-purple.svg) 50% 50% no-repeat;
        background-size: 20px;
      }

      .main-menu__inner-item--all {
        color: $white;
        background-color: $brand-primary;

        a {
          color: $white;
          font-weight: $fontBold !important;

          &::before {
            content: "";
            display: inline-block;
            vertical-align: bottom;
            width: 20px;
            height: 20px;
            background: url(../images/icons/icon-globe.svg) 50% 50% no-repeat;
            background-size: 20px;
            margin-right: $size-3xs;
            margin-left: -2px;
          }

          &:hover,
          &:visited,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }
    }
  }

  .main-menu__inner-link--back {
    padding-left: 52px;
    background-color: $white;
    box-shadow: inset 0 -1px 0 0 $grey-lighter;
    margin-left: -8px;
    width: calc(100% + 8px);
    font-weight: $fontBold !important;
  }

  &__item--parent,
  &__inner-item--parent {

    a {

      @include breakpoint($desktop) {
        color: $brand-primary;

        &:hover,
        &:visited,
        &:active,
        &:focus {
          color: $brand-primary;
        }
      }
    }

    .main-menu__inner-list {
      display: none;
      padding: 0;
      list-style: none outside;
      width: 100%;
      background-color: $white;
      
      @include breakpoint(max-width 1024px) {
        position: absolute;
        top: 0;
        right: 0;
        // padding: 0;
        // list-style: none outside;
        // width: 100%;
        min-height: 100%;
        // background-color: $white;
        transform: translateX(100%);
        transform: translate3d(100%, 0, 0);
        transition: transform 600ms ease;
      }

      @at-root .show-nav & {
        display: block;
      }
    }

    .main-menu__inner-list--level1 {
      
      @include breakpoint($desktop) {
        position: absolute;
        top: $size-8xl;
        left: 0;
        flex-flow: row nowrap;
        padding-top: $size-2xl;
        padding-bottom: $size-2xl;
      }
    }

    li.main-menu__inner-item--level1 {
      
      @include breakpoint($desktop) {
        width: span(4);
        flex-basis: span(4);
        margin-right: gutter();
      }

      &:last-child {
        
        @include breakpoint($desktop) {
          margin-right: 0;
        }
      }

      a {
        
        @include breakpoint($desktop) {
          padding: 0;
          margin-bottom: $size-m;
        }
      }

      &.main-menu__inner-item--all {
        
        @include breakpoint($desktop) {
          display: none;
        }
      }

      &.main-menu__inner-item--parent {
        
        @include breakpoint($desktop) {
          margin-top: $size-2xl;
        }

        > a {

          @include breakpoint($desktop) {
            @include fontSize(16px);
            line-height: 20px;
            letter-spacing: 1px;
            font-weight: $fontBold;
          }
        }
      }
    }

    .main-menu__inner-item--cms_block {
      position: relative;
      padding-left: 0;
      margin: $size-2xl $size-m;
      width: calc(50% - 32px);
      flex-basis: calc(50% - 32px);

      @include breakpoint($desktop) {
        align-self: flex-start;
        margin: 0;
      }

      &:nth-last-child(2) {
        margin-left: auto;
      }

      p,
      a {
        // for the p tag that the WYSIWYG wraps around img tags
        margin-bottom: 0 !important;
        line-height: 0;
        padding: 0;
        color: $white;
      }

      h3 {
        @include fontSize(16px);
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0;
        color: $white;
        font-weight: $fontBold;
        text-align: center;

        @include breakpoint($desktop) {
          text-align: left;
          font-weight: $fontBold;
        }

        a {
          text-transform: uppercase !important;
          line-height: 20px;
          font-weight: $fontBold;
        }
      }

      a {
        color: $white;
        text-transform: none;

        &:hover,
        &:visited {
          color: $white;
        }
      }

      div {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: $size-m;
        background: rgba(66, 43, 102, .8);
        width: 100%;

        &:hover {
          cursor: pointer;
        }

        p {
          display:  none;

          @include breakpoint($desktop) {
            display: block;
            line-height: 20px;
          }

          a {
            
            @include breakpoint($desktop) {
              line-height: 20px;
            }
          }
        }

        a {
          color: $white;
          text-transform: none;

          &:hover,
          &:visited {
            color: $white;
          }
        }
      }
    }

    li.main-menu__inner-item--sign-in {
      background: $brand-tertiary;
      color: $white;
      display: none;

      @include breakpoint(max-width 1024px) {
        order: 9;
      }

      @include breakpoint($desktop) {
        position: absolute;
        top: auto !important; // needed to override inline style added by js
        bottom: 0;
        width: 100% !important;
        flex-basis: 100% !important;
        text-align: center;
      }

      a {
        color: $white;
        font-weight: $fontBold;

        @include breakpoint($desktop) {
          @include fontSize(14px);
          line-height: $size-l;
          margin-bottom: 0 !important;
          width: auto;
          display: inline-block;
          padding: 19px 0 19px $size-3xl;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 20px;
          top: $size-xs;
          height: $size-2xl;
          width: $size-2xl;
          background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
          background-size: $size-xl;

          @include breakpoint($desktop) {
            right: auto;
            left: 0;
            top: 9px;
          }
        }

        &:hover,
        &:visited,
        &:active,
        &:focus {
          color: $white;
        }
      }
    }

    .main-menu__inner-item--level2 {

      &.main-menu__inner-item--all {
        
        @include breakpoint($desktop) {
          display: none;
        }
      }
    }

    // gets added to mobile menu only
    &.show-nav {

      > .main-menu__inner-list {
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
        z-index: 100; // to make sure it is above the parent menu

      }
    }

    // gets added to desktop menu only
    &.item-hovered {

      .main-menu__inner-list {
        display: block;
      }

      .main-menu__inner-list--level1 {
        display: flex;
      }

      > a {
        box-shadow: inset 0 -2px 0 0 $white !important;
      }
    }
  }

  &__screen {
    display: none;
    position: absolute;
    top: 186px;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: $white;
  }
}

.menu-underlay {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
}

.menu-hovered .main-menu__screen {
  
  @include breakpoint($desktop) {
    display: block;
  }
}
