.no--route__wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint($desktop) {
    flex-direction: row;
  }

  &--left,
  &--right {
    background: $brand-noroute;
    @include breakpoint($desktop) {
      width: 50%;
      display: inline-block;
    }
  }

  &--right {
    padding: $size-2xl $size-m;
    text-align: center;

    @include breakpoint($desktop) {
      padding: 96px 136px 160px 91px;
      text-align: left;
    }

    h3 {
      margin-top: $size-2xl;
      margin-bottom: $size-2xs;
    }

    a {
      font-size: $size-m;
      font-weight: $fontReg;
      letter-spacing: 0;
      line-height: $size-xl;
      text-decoration: underline;
      color: $brand-primary;
      cursor: pointer;
      display: block;
    }

    p {
      margin-top: $size-2xl;
      color: $brand-primary;

      @include breakpoint($desktop) {
        margin-top: $size-6xl;
      }

      a {
        display: inline-block;
      }
    }
  }
}

.cms-no-route {
  .page-title-wrapper,
  .page.messages {
    display: none;
  }

  h2.main-title,
  h2.sub-title {
    @include fancyHeading;
    margin: 0;
    font-size: $size-4xl;
    line-height: $size-6xl;

    @include breakpoint($desktop) {
      font-size: 56px;
      line-height: $size-7xl;
    }
  }

  h2.main-title {
    color: $brand-primary;

    @include breakpoint($desktop) {
      margin-bottom: $size-m;
    }
  }

  h2.sub-title {
    color: $white;
  }

  h3 {
    @include mainHeading;
    color: $brand-primary;
    font-weight: $fontReg;
  }

  .page-footer {
    margin-top: 0;
  }
}