.training--wrapper {

  &__book {
    display: flex;
    flex-flow: column wrap;


    @include breakpoint($desktop) {
      flex-flow: row nowrap;
    }

    img {
      width: 100%;
      height: auto;

      @include breakpoint($desktop) {
        width: 50%;
      }
    }

    .booking--wrapper {
      background-color: $brand-secondary;
      padding: $size-2xl $size-m;
      width: 100%;

      @include breakpoint($desktop) {
        padding: 0;

        .booking--form {
          max-width: 80%;
          padding: 98px 0 0 91px;
        }
      }
    }

    h1 {
      color: $white;
      font-family: $fontHeading;
      text-transform: capitalize;
      @include fontSize(40px);
      letter-spacing: 0;
      line-height: $size-6xl;
      text-align: center;
      margin: 0 0 $size-m 0;
    }

    p {
      color: $white;
      font-family: $fontBody;
      @include fontSize($size-m);
      font-weight: 300;
      letter-spacing: 0;
      line-height: $size-xl;
      text-align: center;
      margin: 0;
    }

    .wdform-field {
      position: relative;
    }

    .wdform-element-section.course--options {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 43px;
        right: 8px;
        background: url('../images/icons/icon-chevron-down-dark.svg') no-repeat;
        background-size: 30px 20px;
        width: 30px;
        height: 20px;
      }
    }

    select {
      border: 1px solid $border-lighter;
      border-radius: 2px;
      background-color: $white;
      width: 100%;
      padding: 11px $size-m;
      text-transform: uppercase;
      color: $brand-primary;
      margin: $size-2xl 0;
    }

    button {
      border-radius: 2px;
      background-color: $brand-senary;
      width: 100%;
      padding: 15px;
      text-align: center;
      color: $white;
      text-transform: uppercase;

      &:hover {
        outline: 0;
        border: 0;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }

  &__locations {

    @include breakpoint(max-width $desktop) {
      margin: 32px 16px 64px;
    }

    @include breakpoint($desktop) {
      max-width: 868px;
      margin: $size-7xl auto;
      text-align: center;
    }

    h1 {
      margin-bottom: 32px;
      color: $brand-primary;
      font-family: $fontHeading;
      text-transform: capitalize;

      @include breakpoint($desktop) {
        margin-bottom: 64px;
      }
    }

    .training--groups {
      background-color: $brand-vpale;
      padding: $size-xl;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include breakpoint($desktop) {
        flex-wrap: nowrap;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 50%;

        &:first-of-type {
          border-right: 1px solid $border-light;
        }

        &:nth-child(3) {

          @include breakpoint(max-width $desktop) {
            margin-top: 24px;
            width: 100%;
            padding-top: 24px;
            border-top: 1px solid $border-light;
          }

          @include breakpoint($desktop) {
            border-left: 1px solid $border-light;
          }
        }

        @include breakpoint($desktop) {
          width: 33%;
        }

        li {
          color: $brand-primary;
          @include fontSize(14px);
          font-weight: 500;
          letter-spacing: 1px;
          line-height: $size-l;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: $size-m;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Overrides for generic template styles
.page-id-10460 {
  .container {
    width: 100%!important;
    padding: 0;
    margin: -54px 0 0 0;

    @include breakpoint($desktop) {
      margin: -24px 0 0 0;
    }
  }

  .centred > h1 {
    display: none;
  }

  .fm-form-container {
    display: block!important;

    form {
      background: transparent;
      padding: 0;
    }

    .fm-hide,
    .wdform-label-section,
    .wd-hidden {
      display: none;
    }
  }
}