.page-footer {
  margin-top: $size-7xl;
}

.footer {


  .footer__constrainer.footer__payment-options {
    @include breakpoint($tablet) {
    display: flex;
    justify-content: space-between;

    &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dedede;
    position: absolute;
    left: 0;
    }
  }
}



  &__constrainer {
    // padding-left: $size-m;
    // padding-right: $size-m;


    &--menu {
      padding-left: 0;
      padding-right: 0;

      @include breakpoint($desktop) {
        padding-left: $size-m;
        padding-right: $size-m;
      }

      @include breakpoint(1232px) { // the max width of the container plus two times 16px outer gutters
        padding-left: 0;
        padding-right: 0;
      }

      @include breakpoint($desktop) {
        display: flex;
        flex-flow: row nowrap;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: $grey-lighter;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  &__social {
    padding-top: $size-xs;
    padding-bottom: $size-xs;

    @include breakpoint($desktop) {
      padding-left: 0;
      padding-right: 0;
    }

    .social-icons {
      list-style: none outside;
      padding: 0;
      width: span(2 wider of 4);
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;

      @include breakpoint($tablet) {
        width: span(6);
      }

      @include breakpoint($desktop) {
        width: span(4);
      }
    }

    .social-icons__icon {
      width: $size-2xl;
      height: $size-2xl;
      display: block;

      &--facebook {
        background: url(../images/icons/icon-facebook.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--twitter {
        background: url(../images/icons/icon-twitter.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--youtube {
        background: url(../images/icons/icon-youtube.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--instagram {
        background: url(../images/icons/icon-instagram.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--pinterest {
        background: url(../images/icons/icon-pinterest.svg) 50% 50% no-repeat;
        background-size: contain;
      }
    }
  }

  &__award {

    @include breakpoint($desktop) {
      width: 94px;
      flex-basis: 66px;
      margin-left: auto;
      height: 50%;
      margin-top: $size-2xl;
    }

  }

  .payment--methods {

    @include breakpoint($smallMobile) {
      width: 100%;
      justify-content: space-around;
      order: 2;
      padding: 24px 60px;
      margin: 0;
    }

    @include breakpoint($tablet) {
      width: 50%;
      order: 2;
      margin-right: 40px;
      justify-content: end;
      padding: 24px 0;
      margin: 0;
    }
  }

  .trustpilot-widget {
    width: 50%;

    @include breakpoint($smallMobile) {
      order: 1;
      width: 100%;
      padding: 0px 0px 24px 0;
    }

    @include breakpoint($tablet) {
      width: 36%;
      padding: 24px 0;
    }

    @include breakpoint($desktop) {
      width: 39%;
    }

    @include breakpoint($desktop-mid) {
      width: 36%;
    }

  }

  #mobile.trustpilot-widget {
    display: none;
  }


  .tp-widget-trustscore {
      font-size: 18px;
      display: none !important;
  }

  #trust-score {
    display: none !important;
  }


}


.copyright-container {
  background: $brand-primary;
  padding: 19px 0;
  text-align: center;
  margin: 0 auto;

  .copyright {
    color: $white;
    font-size: $size-s;
    letter-spacing: 1px;
    line-height: $size-l;
    text-transform: uppercase;
  }
}

#mobile.trustpilot-widget {
  display: none;
}

@media (max-width: $tablet) {

  #mobile.trustpilot-widget {
    display: block;
  }

  #desktop.trustpilot-widget {
    display: none;
  }

}
