//
// PDP Media - Fotorama
//

.catalog-product-view {
  .columns .main {
    .product.media {
      position: relative;

      @include breakpoint($desktop) {
        flex-basis: span(9);
        width: 70%;
        display: inline-block;

        .fotorama__thumb-border {
          border-left: 0;
          border-right: 0;
          border-top: 0;
          border-bottom: 2px solid $brand-quaternary;
        }
      }

      .fotorama-item {
        position: relative;
      }

      .fotorama__nav--thumbs {
        @include breakpoint($desktop) {
          display: block !important;
          max-height: 414px;
          overflow: scroll;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .gallery--zoom {
        background: $white;
        position: absolute;
        bottom: 0;
        right: 0;
        height: $size-4xl;
        width: $size-4xl;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
        }

        .icon {
          background: url("../images/icons/icon-zoom.svg");
          background-size: $size-xl $size-xl;
          height: $size-xl;
          width: $size-xl;
        }
      }
    }
  }

  .towishlist {
    height: $size-2xl;
    width: $size-2xl;
    background: url(../images/icons/icon-wishlist-pdp.svg) 50% 50% / 21px 21px no-repeat;

    span {
      @extend %visually-hidden;
    }
  }

  .towishlist--logged-out {
    @include wishlist;
    height: $size-2xl;
    width: $size-2xl;
    background: url("../images/icons/icon-heart-outlined-purple.svg") 50% 50% / 21px 21px no-repeat;

    span {
      @extend %visually-hidden;
    }
  }

  .offwishlist  {
    @include wishlist;
    height: $size-2xl;
    width: $size-2xl;
    background: url("../images/icons/icon-heart-purple.svg") 50% 50% / 21px 21px no-repeat;
    display: none;

    span {
      @extend %visually-hidden;
    }
  }
}

.fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
  display: none !important;
}

.fotorama__nav--dots {
  margin-top: $size-m;

  @include breakpoint($desktop) {
    flex-basis: span(6);
    margin-left: gutter() * 2;
  }

  .fotorama__nav__frame {
    width: $size-2xl;
    height: 2px;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }

    &.fotorama__active {
      .fotorama__dot {
        background: $brand-quaternary;
      }
    }

    .fotorama__dot {
      border-radius: 0;
      border: 0;
      width: $size-2xl;
      height: 2px;
      background: $grey-lighter;
      top: 0;
      right: 0;
    }
  }
}

.fotorama-video-container::after {
  background: url("../images/icons/icon-play-circle.svg") bottom right;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: $size-xs;
  width: 100px;
}

.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
  }
}

div[class*="amlabel-position-top-right"],
div[class*="amlabel-position-bottom-right"] {
  right: $size-m !important;

  @include breakpoint($desktop) {
    right: 78px !important;
  }
}

.towishlist {
  @include wishlist;
  background: url("../images/icons/icon-heart-outlined.svg") 0 0 / 18px 18px no-repeat;

  &:hover {
    background-color: transparent;
  }
}
