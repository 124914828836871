//
// Components : table, sidebar, discount code box
// (find them within styles/components)
//

.checkout-cart-index {

  .page-wrapper {

    .page-title-wrapper {
      margin: 0 0 $size-2xl;

      @include breakpoint($desktop) {
        margin: $size-s 0 $size-7xl;
      }

      .page-title {
        margin: 0;
      }

      h1 {
        @include fancyHeading;
        text-align: center;
      }
    }
  }

  .cart-container {
    padding: 0 $size-m;
    margin-bottom: $size-xl;
  }

  .page-main {
    @include breakpoint($desktop) {
      padding: 0 $size-2xl;
    }

    .columns {
      @include breakpoint($desktop) {
        max-width: 1376px;
        margin: 0 auto;
      }

      .main {
        @include breakpoint($desktop) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          > input,
          .cart-wrapper {
            display: none;
          }

          .cart-container {
            flex-basis: span(10);
          }

          .cart-sidebar {
            flex-basis: span(6);
            max-width: 418px;
          }
        }
      }
    }
  }
  .ampromo-items-add{
    display: none;
  }
  .ampromo-overlay{
    display: none;
  }
}

.actions {
  .continue {
    background: none;
    color: $brand-primary;
    padding: $size-2xl $size-m 0;
    text-align: center;
    width: 100%;
    font-size: $size-s;
    letter-spacing: 1px;
    text-decoration: underline;
    font-weight: 100;

    &:visited {
      color: $brand-primary;
    }
  }
}
