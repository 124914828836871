// Default button styles.
// Override where needed in components

button,
.btn,
.action {
  font-weight: $fontBold;
  font-size: $size-s;
  line-height: $size-l;
  background-color: $brand-primary;
  color: $white;
  padding: 11px $size-m;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  
  &:hover {
    background-color: $brand-secondary;
  }
  
  &:disabled {
    background-color: $grey-lightest;
    color: $grey-dark;
  }
  
  &:focus {
    outline: 0;
  }
}

%secondary-button {
  background-color: $brand-senary;

  &:hover {
    background-color: $brand-quaternary;
  }
}

%tertiary-button {
  background-color: $brand-quinary;

  &:hover {
    background-color: $brand-tertiary;
  }
}

.btn.btn-purple-cta:hover {
  color: #fff;
}

%ghost-button {
  background-color: transparent;
  font-weight: $fontReg;
  box-shadow: inset 1px 0 0 0 $white, inset 0 1px 0 0 $white, inset -1px 0 0 0 $white, inset 0 -1px 0 0 $white;

  &:hover {
    background-color: transparent;
  }
}

%ghost-button-inverted {
  background-color: $white;
  font-weight: $fontReg;
  box-shadow: inset 1px 0 0 0 $grey-lighter, inset 0 1px 0 0 $grey-lighter, inset -1px 0 0 0 $grey-lighter, inset 0 -1px 0 0 $grey-lighter;
  color: $grey-light;

  &:hover {
    background-color: transparent;
  }
}

%link-style-button {
  @include fontSize(14px);
  line-height: $size-l;
  color: #2F1A45;
  letter-spacing: 1px;
  text-decoration: underline;
  text-transform: uppercase;
}


%out-of-stock {
  background-color: $brand-background;
  color: $brand-primary;
  width: 100%;
  padding: 11px $size-m;
  font-weight: $fontBold;
  text-transform: uppercase;
  cursor: default;

  &:hover {
    background-color: $brand-background;
  }
}

.action.out-of-stock,
.catalog-category-view .stock.unavailable {
  @extend  %out-of-stock;
}

//CMS Page Buttons Styling
.container-fluid.container--vibrant-purple .btn-cta {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}