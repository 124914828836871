.customer-account-index,
.sales-order-history,
.customer-account-edit,
.customer-address-index,
.newsletter-manage-index,
.sales-order-view,
.sales-order-invoice,
.wishlist-index-index,
.customer-address-form {
  .page-title {
    font-family: $fontHeading;
    color: $brand-primary;
    text-align: center;
    line-height: $size-4xl;
    text-transform: capitalize;
    margin: $size-2xl 0;
    @include breakpoint($desktop) {
      padding-top: 30px;
      margin-bottom: $size-7xl;
    }
    span {
      letter-spacing: 0;
    }
  }
  .page-main {
    @include breakpoint($desktop) {
      padding: 0 $size-m;
    }
    @include breakpoint($desktop-mid) {
      padding: 0;
    }
  }
  .columns {
    @include breakpoint($desktop) {
      display: grid;
      grid-template-columns: 311px auto;
      grid-gap: $size-7xl;
      max-width: 1168px;
      margin: 0 auto;
    }
    .column.main {
      order: 2;
      grid-row-start: 1;
      grid-column-start: 2;
    }
    .sidebar.sidebar-main {
      order: 1;
      grid-row-start: 1;
      grid-column-start: 1;
    }
  }
  address {
    text-transform: capitalize;
  }
  .add-postcode {
    text-transform: uppercase;
  }
}
.customer-account-index {
  .home-carousel-image-container {
    position: relative;
    margin: 0 $size-m $size-2xl $size-m;
    font-size: 0;
    @include breakpoint($desktop) {
      margin: 0 0 $size-2xl 0;
    }
  }
  .title-home-carousel {
    line-height: $size-2xl;
    position: absolute;
    bottom: $size-2xl;
    left: $size-2xl;
    font-size: 24px;
    margin: 0;
    font-family: $fontHeading;
    text-transform: capitalize;
  }
}

.sales-order-invoice {

  .columns {
    @include breakpoint($tablet) {
      padding-top: 134px;
    }
  }

  .actions-toolbar.order-actions-toolbar,
  .actions-toolbar {
    display: none;
  }

  .actions-toolbar:has(.action.back) {
    display: block;
  }

  .actions-toolbar .secondary {
    a {
      color: #2F1A45;
      font-weight: 300;
      text-decoration: underline;
      background-color: transparent;
      padding: 0;
      margin-bottom: 16px;
    }
  }

  .order-details-items {
    overflow: auto;
    padding: 24px;
    background-color: #f9f8fc;

    .order-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;
    }

    .table-order-items {
      position: relative;
      width: 100%;
      font-size: 11px;
      @include breakpoint($tablet) {
        font-size: 14px;
      }

      .table-caption {
        display: none;
      }
      thead th,
      tfoot th {
        text-align: left;
      }
      th {
        font-size: 11px;
        @include breakpoint($tablet) {
          font-size: 14px;
        }
      }
      tfoot {
        border-bottom: 0;
        td {
          position: absolute;
          right: 38px;
          @include breakpoint($tablet) {
            right: 48px;
          }
        }
      }

    }

  }

  .block-order-details-view {
    .block-content {
      border-top: 1px solid #DEDEDE;
      padding-top: 20px;
    }
  }

}

.customer-account-edit {
  input {
    font-size: $size-m;
    line-height: $size-l;
    letter-spacing: 1px;
  }
}
.hide {
  display: none;
}
.to-lower {
  text-transform: lowercase;
}
.my-account-block {
  padding: 24px $size-4xl;
  margin-bottom: $size-2xl;
  background-color: $background;
  
  @include breakpoint($desktop) {
    overflow: auto;
    padding: 24px;
  }
  &.form-address-edit {
    @include breakpoint($tablet) {
      padding: 24px 20%;
    }
    @include breakpoint($desktop) {
      padding: 24px;
    }
  }
}
.my-account-box {
  background-color: $white;
  border: 1px solid $grey-lighter;
  margin-bottom: 24px;
  padding: $size-m;
  @include breakpoint($desktop) {
    margin-bottom: 0;
  }
  &.box-newsletter {
    margin-bottom: 0;
  }
  &.box-information {
    .box-content {
      p {
        line-height: 24px;
      }
    }
  }
  &.box-address-shipping {
    margin-bottom: 24px;
    @include breakpoint($desktop) {
      margin-bottom: 0;
    }
  }
}
.block-info-content {
  @include breakpoint($desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
}
.order-box {
  margin-bottom: 24px;
  @include breakpoint($desktop) {
    margin-bottom: $size-m;
  }
  &:last-child {
    @include breakpoint($desktop) {
      margin-bottom: 0;
    }
  }
}
.box-title {
  font-weight: 500;
  color: $brand-primary;
  text-transform: uppercase;
  font-size: $size-s;
  letter-spacing: 1px;
  &.item-title-line {
    color: $grey-dark;
    line-height: $size-l;
  }
}
.box-content-header {
  display: flex;
  justify-content: space-between;
  line-height: $size-l;
  margin-bottom: $size-xs;
}
.order-buttons {
  display: flex;
  justify-content: flex-end;
  @include breakpoint($desktop) {
    grid-area: buttons;
    height: fit-content;
    margin-bottom: 24px;
    width: 368px;
    justify-self: end;
  }
  &.individual-order {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: auto;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    color: $white;
    border-radius: 2px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.acc-reorder {
      background-color: $brand-secondary;
    }
    &.acc-view,
    &.acc-download {
      background-color: $brand-senary;
    }
    &.account-action {
      flex: 1;
      @include breakpoint($desktop) {
        max-width: 176px;
        
      }
    }
    &.acc-view + a.acc-reorder {
      margin-left: $size-m;
    }
  }
}
a.acc-view-all,
a.acc-edit-address,
button.acc-add-address,
button.action.save.primary {
  text-decoration: none;
  font-weight: 500;
  color: $white;
  border-radius: 2px;
  background-color: $brand-senary;
  width: 100%;
  letter-spacing: 1px;
  @include breakpoint($desktop) {
    width: auto;
    float: left;
    min-width: 176px;
  }
}
.acc-edit-address,
.acc-add-address {
  @include breakpoint($desktop) {
    margin-top: 24px;
  }
}
.acc-strong {
  font-weight: 500;
  text-transform: uppercase;
  font-size: $size-s;
}
.sales-order-view {

  .column.main {
    display: flex;
    flex-direction: column;

    .return--button {
      order: -1;
    }
  }

  .acc-reorder {
    margin-bottom: $size-m;
    @include breakpoint($desktop) {
      margin-bottom: 0;
      margin-left: $size-m;
    }
  }

  .order-actions-toolbar {
    background: $brand-vpale;
    padding: $size-xl $size-4xl 0;

    a {
      @include breakpoint(max-width $desktop) {
        width: 100%;
      }

      &.action.order {
        margin-top: $size-m;
        background: $brand-secondary;

        @include breakpoint($desktop) {
          margin-left: $size-m;
          margin-top: 0;
          width: 184px;
        }
      }
    }

    @include breakpoint ($desktop) {
      padding: $size-xl $size-xl 0;
      display: flex;
      justify-content: flex-end;
    }
  }

  .items.order-links {
    li {
      list-style-type: none;
      a {
        display: inline-block;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: 0;
        border-radius: 2px;
        background-color: #2F1A45;
        cursor: pointer;
        padding: 11px 20px;
        margin-right: 16px;
      }
    }
  }

  .actions .action.print {
    display: none;
  }

}
.acc-download {
  margin-bottom: 24px;
  @include breakpoint($desktop) {
    margin-bottom: 0;
  }
}
.order-header {
  padding: 11px $size-m;
  border: 1px solid $grey-lighter;
  display: flex;
  justify-content: space-between;
  color: $brand-primary;
  letter-spacing: 1px;
  line-height: $size-l;
  font-size: $size-s;
}
.order-content {
  padding: $size-m;
  border: 1px solid $grey-lighter;
  border-top-width: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;
  text-transform: uppercase;
  color: $brand-primary;
  @include breakpoint($desktop) {
    display: grid;
    grid-template-areas:
    "deliver buttons"
    "total buttons"
    "status buttons";
  }
  > span {
    line-height: $size-l;
    margin-bottom: $size-2xs;
    letter-spacing: 1px;
    font-size: $size-s;
    &:last-of-type {
      margin-bottom: $size-m;
      @include breakpoint($desktop) {
        margin-bottom: 0;
      }
    }
    &:nth-child(1) {
      @include breakpoint($desktop) {
        grid-area: deliver;
      }
    }
    &:nth-child(2) {
      @include breakpoint($desktop) {
        grid-area: total;
      }
    }
    &:nth-child(3) {
      @include breakpoint($desktop) {
        grid-area: status;
      }
    }
  }
}
.order-items {
  border-left: 1px solid $grey-lighter;
  border-right: 1px solid $grey-lighter;
  border-top-width: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.order-items-header {
  display: flex;
  justify-content: space-between;
  letter-spacing: 1px;
  padding: 11px $size-s 11px $size-m;
  border-bottom: 1px solid $grey-lighter;
  color: $brand-primary;
  line-height: $size-l;
  cursor: pointer;
}
.order-items-content {
  display: none;
  padding: $size-m;
  padding-top: 0;
  border-bottom: 1px solid $grey-lighter;
}
.block-title {
  margin-bottom: 24px;
  strong {
    line-height: 32px;
    text-transform: uppercase;
    font-size: 24px;
    color: $brand-primary;
    letter-spacing: 2px;
    font-weight: 300;
  }
}
.edit.edit-link,
.back.back-link {
  padding: 0;
  background-color: transparent;
  color: $brand-primary;
  font-weight: 300;
  text-decoration: underline;
  &:visited {
    color: $brand-primary;
  }
}
.back-link {
  margin: $size-m;
  margin-top: 0;
  font-size: $size-s;
  line-height: $size-l;
  @include breakpoint($desktop) {
    margin-left: 0;
  }
}
.closed {
  .vertical {
    transition: all .5s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all .5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}
.order-items-opened {
  opacity: 1;
  .vertical {
    transition: all .5s ease-in-out;
    transform: rotate(90deg);
  }
  .horizontal {
    transition: all .5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}
.circle-plus {
  height: $size-xs;
  width: 6px;
  font-size: 1em;
  opacity: .7;
}
.circle-plus .circle {
  position: relative;
  width: $size-xs;
  height: 20px;
  border-radius: 100%;
}
.circle-plus .circle .horizontal {
  position: absolute;
  background-color: $brand-primary;
  width: $size-xs;
  height: 2px;
  left: 50%;
  margin-left: -12px;
  top: 50%;
  margin-top: -3px;
}
.circle-plus .circle .vertical {
  position: absolute;
  background-color: $brand-primary;
  width: 2px;
  height: $size-xs;
  left: 50%;
  margin-left: -7px;
  top: 50%;
  margin-top: -8px;
}

.edit-acc-label {
  border: 1px solid $grey-lighter;
  padding: 10px $size-m;
  margin-bottom: 0;
  background-color: $white;
  &::after {
    height: 20px;
    width: 20px;
    content: " ";
    background: url("../images/icons/chevron-up.svg") no-repeat;
    background-size: 20px 20px;
    float: right;
    transition: all .3s;
    transform: rotate(180deg);
    justify-self: right;
    align-self: center;
  }
}
input[type="checkbox"]:checked + label {
  background-color: $background;
}
input[type="checkbox"]:checked + .edit-acc-label::after {
  transform: rotate(0deg);
}
.change-email-dropdown,
.change-password-dropdown {
  padding: $size-m $size-m 0;
  background-color: $white;
  border: 1px solid $grey-lighter;
  border-top-width: 0;
  display: none;
}

input[id="change-password"],
input[id="change-email"] {
  position: absolute;
  left: -99999px;
}

div[id="account-menu-mobile"] .block .title.active::after {
  transform: rotate(0deg);
}

.change-container {
  margin-bottom: $size-m;

  .edit-acc-label {
    width: 100%;
    
    span {
      text-transform: uppercase;
      font-weight: $fontBold;
    }
  }
}

.form-newsletter-manage {
  .field.choice {
    display: flex;
    align-items: center;
    input,
    label {
      margin: 0;
    }
    label {
      text-transform: capitalize;
      margin-left: $size-xs;
    }
  }
}

.table-order-items.my-account-box {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  border-top-width: 0;
  border-bottom-width: 0;
  tr td strong {
    font-weight: 500;
  }
}

.order-details-items.ordered .my-account-block {
  margin-bottom: 0;
  padding-bottom: 0;
  .actions {
    display: flex;
    flex-direction: column;
    @include breakpoint($desktop) {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }
    a {
      @include breakpoint($desktop) {
        width: 184px;
      }
    }
  }
}

.block-order-details-view {
  padding-top: 0;
  .block-content {
    background-color: $white;
    padding: 11px $size-m;
    border: 1px solid $grey-lighter;
    border-top-width: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.item-info-line {
  display: flex;
  justify-content: space-between;
  margin-top: $size-2xs;
  font-size: $size-s;
  line-height: $size-l;
  letter-spacing: 1px;
  .item-price {
    font-weight: 500;
  }
}
.order-item {
  border-bottom: 1px solid $grey-lighter;
  padding-top: $size-m;
  padding-bottom: $size-m;
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
.order-content-view {
  padding: 0 $size-m;
  border: 1px solid $grey-lighter;
  border-top-width: 0;
  border-bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;
  text-transform: uppercase;
  color: $brand-primary;
}
.table-order-items,
th.mark strong,
th.mark {
  font-weight: 500;
  color: $grey-dark;
  text-transform: uppercase;
  font-size: $size-s;
  letter-spacing: 1px;
  line-height: $size-l;
  span.price {
    font-weight: 300;
  }
}
tfoot {
  border-bottom: 1px solid $grey-lighter;
  display: block;
  padding-bottom: $size-m;
  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: $size-2xs;
  }
}
.box-order-shipping-address,
.box-order-shipping-method,
.box-order-billing-address {
  margin-bottom: $size-m;
  .box-title {
    display: block;
    margin-bottom: $size-2xs;
  }
}

.set.billing,
.set.shipping {
  display: flex;
  align-items: center;
  margin-bottom: $size-2xs;
  input {
    margin-bottom: 0;
    margin-right: $size-2xs;
  }
  label {
    margin-bottom: 0;
  }
}
ul.pages-items {
  display: flex;
  padding-left: 0;
  li,
  a {
    list-style: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brand-vpale;
    color: $brand-primary;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    &.action {
      border: 1px solid $grey-lighter;
    }
    &.next {
      background-image: url(../images/icons/icon-chevron-right.svg);
    }
    &.previous {
      background-image: url(../images/icons/icon-chevron-right.svg);
      transform: rotate(180deg);
    }
  }
  li.current {
    background-color: $white;
    border: 1px solid $grey-lighter;
  }
}

.additional-address-box {
  address {
    display: flex;
    flex-direction: column;
  }
}
.additional-address-header {
  display: flex;
  justify-content: space-between;
}

.wishlist-index-index {
  .comment-box,
  .product-item-actions .edit,
  .actions-toolbar .update,
  .actions-toolbar .share {
    display: none;
  }

  .form-wishlist-items {
    background: $background;
    padding: 24px 40px;

    @include breakpoint($desktop) {
      padding: 24px;
    }
  }

  .product-item-actions .delete {
    @include wishlist;
    background: url("../images/icons/icon-heart.svg")  0 0 / 18px 18px no-repeat;
  }

  .products-grid {
    padding: 0;
  }

  .product-items .product-item {
    background: $white;
    margin-bottom: 0;

    @include breakpoint($desktop) {
      width: 33%;

      .product-item-info {
        width: 227px;
      }

      .product-item-actions {
        display: block;
      }
    }

    .price-box {
      margin-top: 0;
    }
  }

  .action.primary {
    padding: 11px;
  }

  .actions-toolbar .primary {

    @include breakpoint($desktop) {
      display: flex;
      justify-content: flex-end;
    }

    .tocart {
      width: 100%;
      background: $brand-senary;

      @include breakpoint($desktop) {
        width: auto;
      }
    }
  }

  .wishlist-price,
  .actions-primary--isTrained,
  .actions-primary--notTrained {
    display: none;
  }

  .wishlist-price {

    .price-including-tax {
      display: none;
    }
  }
}

.no-touchevents .wishlist-index-index .product-items .product-item:hover .product-item-actions {
  position: static;
  margin: $size-m 0 0 0;
}

.logout-page-container {
  text-align: center;
  padding: 0 $size-m;

  .page-title {
    @include fancyHeading;
    margin: $size-7xl 0 32px;

    @include breakpoint($desktop) {
      margin-top: 128px;
    }
  }

  p {
    
    @include breakpoint($desktop) {
      margin-bottom: 128px;
    }
  }
}
