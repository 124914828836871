.country-swapper-menu,
.modal-country-swapper {

  .main-menu__inner-list--level1 {

    a:not(.main-menu__inner-link--back) {
      
      &::before {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        width: 27px;
        height: 20px;
        margin-right: $size-2xs;
      }
    }
  }
}

.modal-country-swapper {

  .modal-header .modal-title {

    &::before {
      background: url(../images/icons/icon-globe.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }

  // .modal-content {
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  .main-menu__inner-list--level1 {
    list-style: none outside;
    padding-left: 0;

    .main-menu__inner-item--all {
      display: none;
    }

    a {
      @include modalListItem;
    }
  }
}

.cs-australia {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-AU.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-uae {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-AE.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-austria {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-AT.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-switzerland {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-CH.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-germany {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-DE.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-spain {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-ES.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-UK {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-GB.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-ireland {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-IE.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-malta {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-MT.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-mexico {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-MX.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-netherlands {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-NL.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-norway {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-NO.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-new-zealand {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-NZ.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-russia {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-RU.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.cs-usa {
  
  a {
    
    &::before {
      background: url(../images/icons/icon-US.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}
