.page-layout-cms_page_view_customerservices {
  
  .breadcrumbs {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    color: $white;

    .items .item {
      color: $white;

      a {
        color: $white;
      }
    }

    @include breakpoint($desktop-mid) {
      margin-left: calc((100vw - 1168px) / 2); // faking the container on one side
      width: 50%;
    }
  }

  .messages {
    margin-bottom: 0;
  }

  .column.main {
    position: relative;
  }

  .customer-services-header {
    position: relative;
    margin-bottom: $size-2xl;

    h1 {
      @include fancyHeading;
      text-align: center;
      color: $white;
      position: absolute;
      top: calc(((100vw * (416/960)) / 2) - 20px); // width of window multiplied by the aspect ratio of mobile image, divided by 2, minus half the line height of the heading
      width: 100%;
      margin: 0;

      @include breakpoint(min-width 961px) {
        font-size: 56px;
        line-height: $size-7xl;
        top: calc(((100vw * (704/2880)) / 2) - 32px); // width of window multiplied by the aspect ratio of desktop image, divided by 2, minus half the line height of the heading
      }
    }
  }

  .customer-services__main-details {
    background-color: $brand-vpale;
    margin-bottom: $size-2xl;

    &-container {
      @extend %constrainer;
      padding: $size-2xl $size-4xl;

      @include breakpoint($desktop-mid) {
        padding: $size-7xl 0;
      }
    }

    p {

      a {
        text-decoration: underline;
      }
    }

    h2 {
      @include fontSize(24px);
      line-height: $size-2xl;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $brand-primary;
      font-weight: $fontReg;
    }

    h4 {
      @include fontSize(16px);
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $brand-primary;
      font-weight: $fontBold;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cs-tabs-wrapper--tab {
    background-color: $white;

    h2 {
      @include fancyHeading;
      margin: 0 0 $size-xl;

      @include breakpoint($desktop) {
        @include fontSize(32px);
        line-height: $size-4xl;
      }
    }

    h4 {
      @include fontSize(16px);
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0 0 $size-2xs;
      font-weight: $fontBold;
    }

    p {
      line-height: $size-xl;
    }
  }

  .cs-tabs-tab-container {
    @extend %constrainer;
    padding: $size-2xl $size-4xl;

    @include breakpoint($desktop) {
      padding: $size-4xl 0;
    }
  }
}