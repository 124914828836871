.checkout-onepage-success,
.checkout-klarna-success {

  .action.print {
    display: none;
  }

  .page-title-wrapper {
    @extend %constrainer;
    padding: 0 16px;

    .page-title {
      @include fancyHeading;
      margin-bottom: $size-2xl;
      margin-top: 0;
      text-align: center;

      @include breakpoint($desktop) {
        margin-top: $size-xs;
        margin-bottom: $size-7xl;
      }
    }
  }

  .column.main {
    @extend %constrainer;
    padding: 0 16px;

    @include breakpoint($desktop) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 0;
    }
  }

  .block-order-details-view {
    padding: 24px;

    @include breakpoint($desktop) {
      flex-basis: span(10);
    }

    .box-order-shipping-address {
      margin-bottom: 0;
    }

    h2 {
      @include fontSize(24px);
      line-height: $size-2xl;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $brand-primary;
      font-weight: $fontReg;
      margin: 0 0 $size-m;
    }

    .block-content {
      border-top: 1px solid $grey-lighter;
      padding: $size-m;

      .box-title {
        line-height: 18px;
        color: $grey-dark;
      }
    }
  }

  .checkout-success-sidebar {

    @include breakpoint($desktop) {
      flex-basis: span(6);
    }
  }

  .checkout-success {
    padding: $size-xl;
    background: $brand-vpale;
    margin-bottom: $size-2xl;

    p {
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $brand-primary;
      font-weight: $fontBold;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .order-number {
        font-weight: $fontReg;
        color: $brand-primary;
      }
    }
  }

  .checkout-success-ctas {
    padding: 0 $size-xl;

    .action.primary {
      background-color: $brand-senary;
      width: 100%;
      margin-bottom: $size-2xl;
      margin-top: 0;
    }

    .action.continue {
      @include fontSize(14px);
      line-height: 18px;
      color: $brand-primary;
      background-color: transparent;
      padding: 0;
      text-decoration: underline;
      width: 100%;
      text-align: center;
      font-weight: $fontReg;
    }
  }

  #klarna_checkout_success {
    display: none; // we might need to work this block into the design somehow
  }

  .block.crosssell {
    width: 100%; // for when there is only one product. Stops it collapsing
  }
}

@media (max-width: 776px) {

div#mmModalReferrerStage1 {
    top: 59% !important;
    max-width: 90% !important;
    left: 55% !important;
}
}
