.product-book-training-online {

  .towishlist--logged-out,
  .towishlist--logged-in {
    display: none !important; // to override style added by JS just for Book Training pages
  }

  .training-product-custom-price {

    .label {
      margin-bottom: $size-3xs;
      font-size: $size-xs;
      line-height: $size-m;
      letter-spacing: .5px;

      @include breakpoint($desktop) {
        @include fontSize(16px);
        line-height: 20px;
        letter-spacing: 1px;
        margin-bottom: 0;
      }
    }

    .value {
      @include fontSize(16px);
      line-height: 20px;

      @include breakpoint($desktop) {
        @include fontSize(24px);
        line-height: 32px;
        letter-spacing: 2px;
      }
    }

    .value.has-regular-price, .price-wrapper.price-including-tax.has-regular-price {
      color:#AB699F;
    }
  
    .value.regular-price{
      margin-right: 8px;
      text-decoration: line-through;
    }
  }

  .training-product.form {

    h4 {
      @include fontSize(16px);
      line-height: 20px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $brand-primary;
      margin: 8px 0 16px;
    }

    .actions.book-training {
      max-width: 100%;
      margin-top: 16px;

      .primary {
        
        @include breakpoint(max-width 1024px) {
          padding-top: 21px;
          padding-bottom: 21px;
        }
      }
    }
  }

  .training-product-option {

    select {
      text-transform: uppercase;
      outline: none;
      cursor: pointer;

      &[disabled="disabled"] {
        background: $grey-lightest;
        color: $grey-light;
      }
    }

    input {
      text-transform: uppercase;
      outline: none;

      &[disabled="disabled"] {
        background: rgba(245, 245, 245, .7);
        color: $grey-light;

        &::placeholder {
          color: $grey-light;
        }
      }

      &::placeholder {
        font-size: $size-m;
      }

      &#online-date-time,
      &#onsite-date-time {
        cursor: pointer;

        dd {
          cursor: pointer;
        }
      }
    }
  }

  .training-product-option {
    position: relative;
    
    select,
    input {
      background-color: transparent;
    }

    select {
      padding: 10px 38px 10px 16px;
    }
  
    &::after {
      content: "";
      width: $size-xl;
      height: $size-xl;
      position: absolute;
      right: $size-xs;
      top: $size-2xs;
      background: url("../images/icons/icon-chevron-down-dark.svg") 50% 50% no-repeat;
      background-size: contain;
      z-index: -1;
    }

    &.disabled::after {
      background: url("../images/icons/icon-chevron-down-disabled.svg") 50% 50% no-repeat;
      background-size: contain;
      z-index: 0;
    }

    &:focus,
    &:active {

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .training-kits {
    @include breakpoint($desktop) {
      display: flex;
      justify-content: space-between;
    }

    .option-kit-level {
      @include breakpoint($desktop) {
        width: 228px;
      }
    }
  }

  .kit-guide-link {
    float: right;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
  }


  // Had to make all this more specific than I would like as the default calendar.css is quite specific
  .booking-training-calendar {
    width: calc(100% - 32px);
    background: $white;
    border-top: 1px solid $grey-lighter;
    border-right: 1px solid $grey-lighter;
    border-bottom: 1px solid $grey-lighter;
    border-left: 1px solid $grey-lighter;
    box-shadow: none;
    padding: $size-m;

    @include breakpoint($desktop) {
      width: span(7);
      max-width: 493px;
    }

    .ui-datepicker-title {

      .ui-datepicker-month,
      .ui-datepicker-year {
        @include fontSize(16px);
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-right: 0;
        font-weight: 500;
      }
    }

    .ui-datepicker-calendar {
      border: 0;

      th {
        @include fontSize(14px);
        line-height: $size-l;
        text-transform: none;
        letter-spacing: 1px;
        color: $grey-light;
        font-weight: 300;
        border: 0;
        background: $white;
      }

      td {
        border: $size-3xs solid $white;
        background: $white;
        text-align: center;
        width: calc(1/7 * 100%);

        a,
        span {
          text-align: center;
        }

        &.ui-state-disabled {
          background: $white;
        }

        &.date-passed {
          background: $grey-lightest;
        }

        &.active-normal-date {
          background: $brand-secondary;
          color: $white !important;
          
          &:hover {
            background: $brand-secondary;
            color: $white !important;
          }
      
          a,
          a:hover {
            color: $white !important;
          }
        }

        &.non-active-full-date {
          background: $brand-background url("../images/icons/icon-close-grey-light.svg") 50% 50% no-repeat;
      
          &:hover {
            background: $brand-background url("../images/icons/icon-close-grey-light.svg") 50% 50% no-repeat;
          }
        }
        
        &.active-low-date {
          background: linear-gradient(135deg, #ddc5e1 0, #ddc5e1 20%, transparent 21%);
          
          &:hover {
            background: linear-gradient(135deg, #ddc5e1 0, #ddc5e1 20%, transparent 21%);
          }
        }

        .ui-state-active,
        .ui-state-hover {
          background: $brand-quaternary;

          :hover {
            background: $brand-quaternary;
          }
        }
      }
    }

    .ui-datepicker-prev {
      background: $grey-lightest url("../images/icons/icon-chevron-right.svg") 50% 50% no-repeat;
      width: $size-2xl;
      height: $size-2xl;
      left: 0;
      top: 0;
      transform: rotate(180deg);

      span {
        display: none;
      }
    }

    .ui-datepicker-next {
      background: $grey-lightest url("../images/icons/icon-chevron-right.svg") 50% 50% no-repeat;
      width: $size-2xl;
      height: $size-2xl;
      right: 0;
      top: 0;

      span {
        display: none;
      }
    }

    .calendar-key {
      margin-top: $size-m;
      padding: $size-m 0 0 0;
      border-top: 1px solid $grey-lighter;
    }

    .calendar-key__low {
      line-height: $size-xl;
      display: inline-block;

      &::before {
        content: "";
        width: $size-m;
        height: $size-m;
        background: linear-gradient(135deg, #ddc5e1 0, #ddc5e1 50%, transparent 51%);
        display: inline-block;
        vertical-align: sub;
        margin-right: $size-2xs;
      }
    }
  }

  .product-tabs__wrapper {

    @include breakpoint(1025px 1200px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .product-tabs__wrapper .tab-title {

    @include breakpoint(1080px 1200px) {
      margin-right: 24px;
    }

    @include breakpoint(1025px 1079px) {
      margin-right: 18px;
    }
  }

  // .product-tabs__wrapper--tab .tab-content {

  //   .main-title {
  //     margin-bottom: $size-2xl;
  //   }
  // }

  .tt-content {

    @include breakpoint($desktop) {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      max-width: 100% !important;
    }

    .tt-text {
      
      @include breakpoint($desktop) {
        flex-basis: span(7);
        margin-right: span(1 wider);
      }
    }

    .tt-image {
      margin-top: $size-2xl;

      @include breakpoint($desktop) {
        flex-basis: span(8);
        padding-left: span(1);
        padding-right: span(1 wide);
        margin-top: 0;
      }
    }
  }

  #trained_elsewhere {

    .action {
      background-color: $brand-senary;

      @include breakpoint(max-width 1024px) {
        width: 100%;
      }
    }
  }

  .section--light {
    h2 {
      margin-top: $size-3xl;
      margin-bottom: $size-l;
    }

    h3 {
      margin-bottom: 0;
      margin-top: $size-m;
    }

    ul {
      margin-bottom: $size-3xs;
      margin-top: $size-m;
    }

    p {
      margin-top: $size-xl;
    }
  }
}

.product-book-training-online .section--light p { margin-top: 10px }