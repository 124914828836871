// Search box/bar in header

.block-search {
  width: span(16);
  padding-top: $size-m;
  height: 72px;
  transition: all 600ms ease;

  @include breakpoint($desktop) {
    padding-top: $size-3xs;
  }

  &.mobile-searchbar-visible {
    height: 72px;
  }

  &.mobile-searchbar-hidden {
    height: 0;
    overflow: hidden;
  }

  .minisearch {
    padding: 0;
    background: $brand-primary;
    position: relative;

    .input-text {
      border-color: $brand-primary;
      background-color: $brand-secondary;
      color: $white;
      outline: none;
      padding-left: $size-5xl;
      height: 40px; // explicit for iOS

      &::placeholder {
        color: $white;
        text-transform: uppercase;
      }

      @include breakpoint($desktop) {
        margin-bottom: 0;
      }
    }

    .actions {
      position: absolute;
      left: 1px;
      top: 1px;
    }

    .action.search {
      padding: 0;
      background-color: $brand-secondary;

      &::before {
        @include icon-svg-reset;
        background: url(../images/icons/icon-search.svg) 50% 50% no-repeat;
        background-size: $size-xl $size-xl;
        width: $size-5xl;
        height: 38px;
      }

      span {
        @extend %visually-hidden;
      }
    }
  }
}

.klevuResultsBlock [class^="klevu-price"],
.kuResultList .kuPrice {
  display: none;
}
