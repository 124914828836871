.product-main__wrapper {
  @include breakpoint($desktop) {
    display: flex;
    justify-content: space-between;
  }

  // Next four blocks are workarounds related to Amasty labels
  // where the only way to select elements is via partial strings.
  // This is due to Amasty is only assigning specific classes with
  // product sku, such as '.amlabel-position-bottom-left-4731-prod'

  div[class*="amlabel-position-bottom-left"] {
    @include breakpoint($desktop) {
      transform: translateX(95px);
    }
  }

  div[class*="amlabel-position-bottom-right"] {
    transform: translateX(15px) translateY(30px);

    @include breakpoint($desktop) {
      transform: translateX(35px) translateY(0);
    }
  }

  div[class*="amlabel-position-top-left"] {
    @include breakpoint($desktop) {
      transform: translateX(95px) translateY(0);
    }
  }

  div[class*="amlabel-position-top-right"] {
    transform: translateX(15px) translateY(-50px);

    @include breakpoint($desktop) {
      transform: translateX(35px) translateY(5px);
    }
  }
}

.product-info__wrapper {
  @include breakpoint($desktop) {
    flex-basis: span(7);
    display: inline-block;
    vertical-align: top;
  }

  // .product-info-main-container--pro {
  //   display: none;
  // }

  .pro--wrapper {
    .book-training {

      .primary {
        width: 100%;
      
        @include breakpoint(max-width 1024px) {
          padding-top: 21px;
          padding-bottom: 21px;
        }

        a {
          color: $white;
        }
      }
    }
  }

  .trustpilot-widget {
    @include breakpoint($desktop) {
      margin-top: $size-2xs;
    }
  }
}

.product-modal__message {
  display: none;
}

.product-options-wrapper .attendee-permanent-notice {
  width: 100%;
}

.bundle-options-wrapper {
  display: none;
}
.page-product-bundle {
  .product-info-main {
    .price-box { 
      >.price-final_price {
        display: none;
      }
    }
  }
}
.block-bundle-summary  {
  ul,
  .price-box {
    display: none;
  }
}

.table-wrapper.grouped {
  .item {
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    font-weight: $fontReg;
    text-transform: uppercase;
    @include breakpoint($desktop) {
      font-size: 0.875rem;
    }
    &.name {
      flex: 1;
      max-width: 114px;
      @include breakpoint($desktop) {
        max-width: 212px;
      }
    }
    &.qty {
      padding-right: 42px;
      padding-left: 20px;
      width: auto;
      select {
        margin: 0;
      }
    }
    &.status {
      padding-right: 38px;
    }
    select {
      font-size: 0.75rem;
      @include breakpoint($desktop) {
        font-size: 0.875rem;
      }
      padding: 10px 38px 10px 16px;
      background-color: transparent;
    }
    .qty-option {
      position: relative;
      &::after {
        content: "";
        width: $size-xl;
        height: $size-xl;
        position: absolute;
        right: $size-xs;
        top: $size-2xs;
        background: url("../images/icons/icon-chevron-down-dark.svg") 50% 50% no-repeat;
        background-size: contain;
        z-index: -1;
      }

      &.disabled::after {
        background: url("../images/icons/icon-chevron-down-disabled.svg") 50% 50% no-repeat;
        background-size: contain;
        z-index: 0;
      }

      &:focus,
      &:active {

        &::after {
          transform: rotate(180deg);
        }
      }
    }

    .price-box {
      font-size: 0.75rem;
      @include breakpoint($desktop) {
        display: flex;
        font-size: 0.875rem;
      }
      .old-price {
        margin-right: 0;
        @include breakpoint($desktop) {
          margin-right: 8px;
        }
      }
    }

    .price-including-tax {
      display: none;
    }

    .old-price {
      margin-right: 8px;
      .price-including-tax {
        display: block;
      }
    }

    .special-price {
      .price-excluding-tax::after {
        content: '(ex VAT)';
        color: #2F1A45;
        font-size: 14px;
        font-weight: 300;
        text-transform: none;
      }
    }

  }
}

.product.attribute.overview {
  padding: 0 $size-m;
  margin-bottom: $size-m;
  cursor: pointer;

  @include breakpoint($desktop) {
    padding: 0;
  }

  p {
    color: $grey-dark;
    font-size: $size-m;
    font-weight: $fontReg;
    line-height: $size-xl;
    margin-bottom: 16px;
  }
  .bundle.items {
    list-style: none;
    padding: 0;
    .label {
      display: none;
    }
  }
}

.product-info-main {

  .product-info-price {
    margin-bottom: $size-2xl;
    color: $brand-primary;
    letter-spacing: 1px;
    font-size: $size-m;
    line-height: 20px;
    display: flex;
    justify-content: space-between;

    .price-container {
      display: flex;

      .price-label {
        order: 0;
        padding-right: $size-2xs;
      }

      .price-including-tax {
        display: none;

        //temp fix
        &::before {
          content: "(";
        }
        
        &::after {
          content: " " attr(data-label) ")";
        }
      }

      .price-wrapper {
        font-weight: $fontBold;
        font-size: $size-m;
        line-height: 20px;
        order: 1;

        @include breakpoint($desktop) {
          font-size: $size-xl;
          line-height: $size-2xl;
          letter-spacing: 2px;
        }

        &.price-excluding-tax {
          &:after {
            content: '(ex VAT)';
            color: #2F1A45;
            font-size: 14px;
            font-weight: 300;
          }
        }

      }

      .price-label,
      .price-including-tax {
        color: $brand-primary;
        font-weight: $fontReg;

        @include breakpoint($desktop) {
          font-size: $size-s;
          line-height: $size-2xl;
          letter-spacing: 1px;
        }
      }
    }

    .old-price .price-including-tax {
      display: block;
    }

    .old-price,
    .special-price {
      display: inline-block;
    }

    .old-price .price-wrapper {
      @include breakpoint($desktop) {
        font-size: 22px;
        margin-right: 5px;
      }
      &.price-including-tax::after {
        display: none;
      }
    }
    .price-configured_price + .price-final_price {
      .page-product-bundle & {
        display: none;
      }
    }
  }

  .items {
    background: $brand-background;
    padding: $size-m;
    margin: 0 $size-m $size-2xl;

    @include breakpoint($desktop) {
      margin: 0 0 $size-2xl;
    }

    h4 {
      @include subHeading;
      margin: 0 0 $size-2xs;
    }

    li {
      list-style: none;
      color: $brand-primary;
      font-size: $size-m;
      line-height: $size-xl;

      strong {
        font-weight: $fontBold;
      }
    }
  }

  div.fieldset {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:focus {
      outline: none;
    }
  }

  .field.configurable {
    position: relative;
    width: 47%;
    display: inline-block;

    @include breakpoint($desktop) {
      width: 48%;
    }

    &:nth-child(even) {
      margin-left: $size-m;
    }

    &:only-of-type {
      width: 100%;
      margin: 0;
    }

    div.mage-error {
      display: none !important;
    }

    .control {
      &::after {
        content: "";
        background: url("../images/icons/icon-chevron-down-dark.svg");
        position: absolute;
        right: $size-2xs;
        top: $size-2xs;
        height: $size-xl;
        width: $size-xl;
        transition: all .6s;
      }

      &.disabledSelect {
        &::after {
          background: url("../images/icons/icon-chevron-down-inactive.svg");
        }
      }
    }

    select {
      @include subHeading;
      font-weight: $fontReg;

      &[disabled] {
        background: $grey-lightest;
        border: 1px solid $grey-lighter;
        color: $grey-light;
      }

      &.mage-error {
        border: 2px solid $brand-primary;
      }

      &:focus {
        outline-color: $brand-quaternary;
      }
    }
  }

  .product-add-form {
    padding: 0 $size-m;

    @include breakpoint($desktop) {
      padding: 0;
    }

    form,
    .form {
      background: transparent;
      border-top: 1px solid $border-lighter;
      padding: $size-2xl 0;

      @include breakpoint($desktop) {
        padding: $size-2xl 0 0;
      }

      div.form-input {
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
        background: transparent;

        &.disabled {
          background: $grey-lightest;
          color: $grey-light;
        }
      }
    }

    .product-options-bottom {

      @include breakpoint($desktop) {
        margin-top: $size-m;
      }
    }

    .qty {
      width: 100%;
      position: relative;

      @include breakpoint($desktop) {
        width: auto;
      }

      .control {
        display: flex;
      }

      .input-text {
        width: 100%;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        text-align: center;
        margin-bottom: 0;
        height: 40px; // declare height explicitly for benefit of iOS devices

        @include breakpoint($desktop) {
          width: $size-6xl;
          height: $size-6xl;
        }
      }

      .qty-box {
        @include qtyBox;
        height: $size-4xl;

        &:first-of-type {
          border-radius: 2px 0 0 2px;
        }
      }

      #qty-error {
        color: $error;
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 18px;

        @include breakpoint($desktop) {
          bottom: -42px;
        }
      }
    }
    
    .actions {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

      @include breakpoint(max-width $desktop) {
        z-index: 9; // needs to be above everything
      }

      @include breakpoint($desktop) {
        position: static;
        width: 100%;
        max-width: 264px;
      }

      @include breakpoint(1200px) {
        max-width: 324px;
      }

      .page-product-grouped & {
        position: static;
        max-width: 100%;
      }

      .page-product-bundle & {
        position: static;
        margin-top: 16px;
        @include breakpoint($desktop) {
          margin-top: 0;
        }
      }

      .action {
        border-radius: 0;
        width: 100%;
        background: $brand-secondary;

        @include breakpoint($desktop) {
          height: $size-6xl;
        }

        &--isTrained {
          display: none;
        }

        &--notTrained {
          display: none;
          line-height: 18px;
          padding-top: 21px;
          padding-bottom: 21px;

          @include breakpoint($desktop) {
            display: none !important; // we dont want this button to show on desktop
          }
        }

        span {
          font-size: $size-s;
          font-weight: $fontBold;
          letter-spacing: 1px;
          color: $white;
        }
      }

      .tocart {
        
        @include breakpoint(max-width 1024px) {
          padding-top: 21px;
          padding-bottom: 21px;
        }
      }
    }
  }

  .pay-later-container {
    padding: 0 1rem;

    @include breakpoint($desktop) {
      padding: 0;
    }
  }
}

.product-info-main-container--pro {

  .product-info-price,
  .prices-tier {
    display: none;
  }

  .prices-tier {

    .price-including-tax {
      display: none;
    }
  }
}

.pro--container {
  background: $brand-background;
  padding: $size-xl;
  margin: $size-2xl $size-m;
  display: none;

  @include breakpoint($desktop) {
    margin: $size-2xl 0;
  }

  h4 {
    @include subHeading;
    margin: 0 0 $size-2xs;
    text-align: center;
    font-size: $size-m;
  }

  p {
    color: $brand-primary;
    font-weight: $fontReg;
    font-size: $size-m;
    line-height: $size-xl;
    margin-bottom: $size-m;
    text-align: center;
  }

  .actions .primary {
    width: 100%;
    border-radius: 2px;
    background: $brand-senary;

    span {
      text-align: center;
    }
  }
}

.catalog-product-view {
  .page-header {
    z-index: 997; // to beat Amasty product label values added dynamically via inline css

    &::after {

      @include breakpoint($desktop) {
        z-index: 997;
      }
    }
  }

  .my-account-link--loggedOut {
    @include breakpoint($desktop) {
      position: static;
    }
  }
}

.page-title-wrapper.product {

  @include breakpoint($desktop) {
    padding: 0;
  }

  h1 {
    color: $brand-primary;
    font-size: $size-xl;
    line-height: $size-2xl;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: $fontBold;
    margin-top: $size-2xl;
    margin-bottom: $size-m;

    @include breakpoint($desktop) {
      margin-top: 0;
      font-size: $size-2xl;
      line-height: $size-4xl;
    }
  }
}

.available--dates {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -9;
  margin: 0;
  border: 1px solid #ededed;
  max-height: 308px;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
  transition: opacity .4s ease;

  &::-webkit-scrollbar {
    width: 22px;
    background-color: #D2C9DD;
  }

  &::-webkit-scrollbar-button {
    background-color: #D2C9DD;
    color: #856FA9;
  }

  &::-webkit-scrollbar-track {
    background-color: #D2C9DD;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #856FA9;
  }

  &.active {
    opacity: 1;
    z-index: 9;
  }

  p,a {
    margin: 0;
    padding: 0;
    color: #2F1C45;
    font-size: 16px;
    letter-spacing: .69pt;
    width: auto;
    display: inline-block;
    font-weight: 400;
  }

  &__heading {
    background: #EBE9EC;
    padding: 10px 16px;
    box-sizing: border-box;

    p {
      font-weight: 500;
    }
  }

  &__entry {
    margin: 0;
    background: #fff;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    transition: background .4s ease;
    cursor: pointer;

    &.selected, &:hover {
      background: #422B66;

      p, a {
        color: #fff;
      }
    }
  }
}