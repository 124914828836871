.showcart {
  padding: 0;
  position: relative;

  &:hover {
    background: $brand-primary;
  }

  .counter {
    position: absolute;
    right: 3px;
    bottom: 3px;
    background: $white;
    color: $brand-primary;
    width: $size-l;
    height: $size-l;
    border-radius: 50%;
    padding-top: 2px;

    &.empty {
      display: none; //for now
    }

    .counter-number {
      @include fontSize(12px);
      line-height: $size-s;
      font-weight: $fontBold;
      vertical-align: top;
      margin-left: 1px;
    }

    .counter-label {
      display: none;
    }
  }
}

.modal-minicart {

  .modal-title {

    &::before {
      background: url(../images/icons/icon-basket.svg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}

.block-minicart {

  .block-title {
    display: none;

  }

  .block-content {
    padding: $size-m;

    #btn-minicart-close {
      display: none;
    }

    .subtitle.empty {
      @include fontSize(16px);
      line-height: 21px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $brand-primary;
    }

    .minicart__totals-and-ctas {
      background-color: $brand-vpale;
      padding: $size-xl;
      margin-bottom: $size-m;
      display: flex;
      flex-flow: row wrap;
    }

    .items-total {
      @include fontSize(16px);
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: $fontBold;
      color: $brand-primary;
      width: 50%;
      flex-basis: 50%;
      height: 20px;

      span {
        height: 20px;
      }
    }

    .subtotal {
      width: 50%;
      flex-basis: 50%;
      text-align: right;
      height: 20px;

      .label {
        display: inline-block;
        margin-bottom: 0;
      }

      .amount {
        @include fontSize(14px);
        line-height: $size-l;
        color: $brand-primary;
        display: inline-block;
        margin-left: $size-3xs;
      }
    }

    .actions {
      width: 100%;
      flex-basis: 100%;
      margin-top: $size-m;

      .action.checkout {
        width: 100%;
        margin-bottom: $size-m;
      }

      .action.viewcart {
        @extend %link-style-button;
        background-color: transparent;
        padding: 0;
        width: 100%;
        font-weight: $fontReg;
      }
    }

    .update-cart-item {
      display: none !important; // neeed to override inline style that gets added after you interact with the qty input field
    }

    .minicart-items {
      list-style: outside none;
      padding: 0;

      .item {
        padding: $size-m;
        box-shadow: inset 0 1px 0 0 $grey-lighter;
        display: flex;
        flex-flow: row nowrap;

        .product-item-photo {
          width: $size-7xl;
          flex-basis: $size-7xl;
          margin-right: $size-m;
          flex-shrink: 0;
        }

        .product-item-details {
          flex-grow: 1;
        }

        .product-item-name {
          @include fontSize(14px);
          line-height: $size-l;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: $fontBold;
          margin-bottom: $size-2xs;
          display: block;
        }

        .product.options {
          @include fontSize(14px);
          line-height: $size-l;
          margin-bottom: $size-2xs;
          .price {
            display: none;
          }
        }

        .price-container {
          @include fontSize(14px);
          line-height: $size-l;
          font-weight: $fontBold;

          .price-including-tax {
            font-weight: $fontReg;
          }
        }

        .product.actions {
          width: 120px;
          flex-basis: 120px;
          margin-left: $size-m;
          margin-top: 0;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
        }

        .qty {
          display: flex;
          flex-flow: row nowrap;
          border: 1px solid $grey-lighter;

          .qty-click-event {
            width: $size-4xl;
            height: $size-4xl;
            text-align: center;
            line-height: 38px;

            &:hover {
              cursor: pointer;
            }

            &[direction="subtract"] {

              &::before {
                @include icon-svg-reset;
                width: 20px;
                height: 20px;
                background: url(../images/icons/icon-minus.svg) 50% 50% no-repeat;
                background-size: 20px 20px;
              }

              span {
                @extend %visually-hidden;
              }
            }

            &[direction="add"] {

              &::before {
                @include icon-svg-reset;
                width: 20px;
                height: 20px;
                background: url(../images/icons/icon-plus.svg) 50% 50% no-repeat;
                background-size: 20px 20px;
              }

              span {
                @extend %visually-hidden;
              }
            }
          }

          .qty-box {
            border: 0;
            outline: none;
            padding: 0;
            margin: 0;
            width: $size-4xl;
            height: $size-4xl;
            text-align: center;
            border-radius: 0;
            border-left: 1px solid $grey-lighter;
            border-right: 1px solid $grey-lighter;
          }
        }

        .action.edit,
        .action.delete {
          @extend %link-style-button;
          background-color: transparent;
          padding: 0;
          font-weight: $fontReg;
          margin-left: $size-xs;
        }

        .edit-and-remove {
          text-align: right;
        }
      }
    }
  }

  .minicart-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .3) url(../images/loader-1.gif) 50% 50% no-repeat;
    background-size: 30px;
    width: 100%;
    height: 100%;
  }
}

.modal-content {
    border: none;
    box-shadow: none;
}

button.action-close {
    background-color: #2F1A45 !important;
    /* display: none; */
    border: none;
}

.modal-header {
    padding: 0px;
    border-bottom: 1px solid #e5e5e5;
}
