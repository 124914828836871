// Generic styles for modals.

//
// START
// Do not remove or override this block
// JS will not remove the _has-modal class from the body if removed.
// Modals will then fail after first use.
//
// .modal-slide,
// .modal-popup {
//   visibility: hidden;
//   opacity: 0;
//   //transition: visibility 0s 1.2s, opacity 1.2s ease;
// }
// .modal-slide._show,
// .modal-popup._show {
//   visibility: visible;
//   opacity: 1;
//   //transition: opacity 1.2s ease;
// }

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
  opacity: 1;
  padding-bottom: 64px; // to account for iOS Safari browser controls/buttons 
}
//
// END
//

.modal-slide,
.modal-popup {
  width: 100%; // no !important here
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  bottom: 0;
  transform: translateX(100%);
  transform: translate3d(100%, 0, 0);
  transition: transform 600ms ease;
  z-index: 1010;
  min-height: 100vh;
  height: 100vh;
  background: $white;
  outline: none;

  @include breakpoint(500px) {
    max-width: 480px; // so its never wider than the mobile design
  }
}

.modal-slide._show,
.modal-popup._show {
  width: 100% !important; // needs !important here to override the inline style that is there on page load
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
  overflow: auto;
  visibility: visible !important; // needs !important here to override the inline style that is there on page load
  opacity: 1 !important; // needs !important here to override the inline style that is there on page load
}

.modal-slide._noshow,
.modal-popup._noshow {
  width: 0; // no !important here
  visibility: hidden;
  opacity: 0;
}

.modals-overlay {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-header {
  background: $brand-primary;
  padding: 0 28px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: $size-7xl;
  
  .modal-title {
    @include fontSize(16px);
    font-weight: $fontBold;
    display: inline-block;
    line-height: $size-7xl;
    height: $size-7xl;
    color: $white;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
    letter-spacing: 1px;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: text-bottom;
      width: $size-xl;
      height: $size-xl;
      margin-right: $size-2xs;
      margin-left: -2px;
    }
  }
  
  .action-close {
    width: $size-xl;
    height: $size-xl;
    background: url(../images/icons/icon-close.svg) 50% 50% no-repeat;
    background-size: cover;
    align-self: center;
    margin: 20px 0;
    padding: 0;

    span {
      @extend %visually-hidden;
    }
  }
}

.modal-content {
  // padding-left: $size-m;
  // padding-right: $size-m;
  padding: 0;
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 0 $size-xl;
  
  .action.secondary {
    margin-left: $size-m;
  }
}

.modal-popup.confirm {
  
  &._show {
    
    @include breakpoint($desktop) {
      min-height: auto;
    }
  }
  
  &+ .modals-overlay {
    z-index: 1195 !important; // needs important to override inline style added by js. And needs to be slightly less than the modal, but still higher than the other elements
  }
  
  .modal-header {
    
    .action-close {
      margin-left: auto;
    }
  }

  .modal-content {
    padding: $size-m;
  }
  
  .modal-footer {
    
    @include breakpoint($desktop) {
      padding: 0 $size-2xl;
    }
  }
  
  .action-primary {
    order: 1;
    margin-right: $size-m;
  }
  
  .action-secondary {
    order: 2;
  }
}
