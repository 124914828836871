.page-header {
  background-color: $brand-primary;
  color: $white;
  position: fixed;
  top: 0;
  z-index: 40; // to trump 30 on PLP Header Sign In button
  max-width: 100vw; // to prevent modals expanding and contracting the header during animated slide in and out

  @include breakpoint($desktop) {
    position: fixed;
  }

  &::after {

    @include breakpoint($desktop) {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $brand-secondary;
      position: absolute;
      left: 0;
      top: 130px;
      z-index: 100;
    }
  }

  .skip {
    @extend %visually-hidden;
  }
}

.header {

  &.content {
    display: flex;
    flex-flow: row wrap;
    padding-top: $size-m;
    align-items: center;

    @include breakpoint($desktop) {
      padding-top: $size-xl;
      //padding-bottom: $size-xl;
      position: relative; // for abs pos search box
    }
  }

  .header-mobile-toggles {
    width: span(4);
    flex-basis: span(4);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @include breakpoint($tablet) {
      justify-content: flex-start;
    }

    @include breakpoint($desktop) {
      width: 0;
      flex-basis: 0;
      display: none; // needed for Firefox
    }
  }

  .nav-toggle {

    &::before {
      @include icon-svg-reset;
      background: url(../images/icons/icon-menu.svg) 50% 50% no-repeat;
      background-size: cover;
      transition: 300ms background ease;
    }

    span {
      @extend %visually-hidden;
    }

    &.open {

      &::before {
        @include icon-svg-reset;
        background: url(../images/icons/icon-menu-close.svg) 50% 50% no-repeat;
        background-size: cover;
      }
    }
  }

  .search-toggle {
    // display: none; // to be toggled when header goes from static to sticky state on scroll
    height: 1px;
    transition: all 600ms ease;
    overflow: hidden;
    vertical-align: bottom;
    display: inline-block;

    &::before {
      @include icon-svg-reset;
      background: url(../images/icons/icon-search.svg) 50% 50% no-repeat;
      background-size: cover;
    }

    &:hover {
      cursor: pointer;
    }

    @include breakpoint($tablet) {
      margin-left: gutter();
    }

    span {
      @extend %visually-hidden;
    }
  }

  .header__logo-container {
    width: span(8);
    flex-basis: span(8);
    margin: 0 gutter();
    text-align: center;
    height: $size-2xl;

    @include breakpoint($desktop) {
      width: span(2 wider);
      flex-basis: span(2 wider);
      margin: 0 0 0 span(7);
      height: $size-6xl;
    }
  }

  .header__logo {
    max-width: 94px; // magic number from design

    @include breakpoint($desktop) {
      max-width: 141px;
    }
  }

  .country-swapper {
    @extend %u-desktop-only;

    @include breakpoint($desktop) {
      width: span(2 wide);
      flex-basis: span(2 wide);
      margin-left: auto;
      text-align: right;
    }

    &__icon {

      &:hover {
        cursor: pointer;
      }

      &::before {
        @include icon-svg-reset;
        background: url(../images/icons/icon-globe.svg) 50% 50% no-repeat;
        background-size: cover;
      }
  
      [aria-hidden="true"] {
        @extend %visually-hidden;
      }
    }

    &__current {
      @extend %visually-hidden;
      // text-transform: uppercase;
      // display: inline-block;
      // vertical-align: middle;
      // font-size: $size-s;
      // line-height: $size-l;
    }
  }

  .my-wishlist-link {
    @extend %u-desktop-only;

    @include breakpoint($desktop) {
      margin-left: 28px;
    }

    a {
      @include fontSize(14px);
      line-height: 18px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        cursor: pointer;
        color: $white;
      }

      .my-wishlist-link__icon {
        @include icon-svg-reset;
        background: url(../images/icons/icon-wish-list-white.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      .label {
        @extend %visually-hidden;
      } 
    }
  }

  .header-account-bag-container {
    width: span(4);
    flex-basis: span(4);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @include breakpoint($tablet) {
      justify-content: flex-end;
    }

    @include breakpoint($desktop) {
      width: auto;
      flex-basis: auto;
      //margin-left: $size-2xl;
    }

    @include breakpoint($desktop-mid) {
      // width: calc(72px + (100% - 480px)/16*3); // having to use the compiled susy output so I can tweak it by a non-standard amount to match the break from the grid in the designs
      // flex-basis: calc(72px + (100% - 480px)/16*3);
      // margin-left: gutter();
      justify-content: flex-end;
    }
  }

  .my-account-link {

    @include breakpoint($desktop) {
      margin-left: 28px;
    }

    &:hover {
      cursor: pointer;
    }

    &__clickzone {
      display: none;

      .label {
        font-weight: $fontReg;
      }

      &--fake {
        display: block;
      }
    }

    &__icon {

      &::before {
        @include icon-svg-reset;
        background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
        background-size: cover;
      }
    }

    .label {
      @extend %visually-hidden;
    }
  }

  .minicart-wrapper {

    @include breakpoint($tablet) {
      margin-left: gutter();
    }

    @include breakpoint($desktop) {
      margin-left: 28px; // need to remove 4px of space to avoid wrapping
    }

    .showcart {
      text-decoration: none;
      color: $white;
      display: block;

      &:visited,
      &:hover,
      &:active,
      &:focus {
        color: $white;
      }
      
      &::before {
        @include icon-svg-reset;
        background: url(../images/icons/icon-basket.svg) 50% 50% no-repeat;
        background-size: cover;
      }
  
      .text {
        @extend %visually-hidden;
      }
    }
  }

  // .main-menu {
  //   width: 100%;
  //   position: absolute;
  //   top: $size-7xl;
  //   left: -100%;
  //   transform: translateX(0);
  //   transform: translate3d(0, 0, 0);
  //   transition: 600ms transform ease;

  //   @at-root .show-nav & {
  //     transform: translateX(100%);
  //     transform: translate3d(100%, 0, 0);
  //     overflow: scroll;
  //     z-index: 1100; // to trump amasty label at 995 and sidebar at 1000
  //   }

  //   @include breakpoint($desktop) {
  //     position: static;
  //     width: 100%;
  //     flex-basis: span(16);
  //   }
  // }

  .block-search {

    @include breakpoint($desktop) {
      position: absolute;
      top: $size-xl;
      left: 0;
      width: span(5);
    }

    .block-title,
    .minisearch .label {
      @extend %visually-hidden;
    }

    .nested {
      display: none;
    }
  }
}
