//
// Found on Cart, Checkout
//

.cart-summary,
.opc-sidebar {
  background: $brand-background;
  padding: $size-xl;
  margin-top: $size-2xl;

  @include breakpoint($desktop) {
    margin-top: 0;
  }

  .title {
    color: $brand-primary;
    font-weight: $fontBold;
    font-size: $size-m;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 20px;
    position: relative;
    width: 100%;
    display: block;
    transition: all .6s;
    padding-bottom: $size-m;
    border-bottom: 1px solid $border-light;
  }

  #subtotal {
    display: inline-block;
    float: right;
    height: $size-l;
    color: $brand-primary;
    font-size: $size-s;
    font-weight: $fontBold;
    letter-spacing: 1px;
    line-height: $size-l;
    padding-right: $size-2xl;

    &:last-of-type {
      display: none;
    }

    .price {
      font-weight: $fontReg;
      margin-left: $size-2xs;
    }
  }

  .cart-totals,
  .opc-block-summary {
    margin-top: $size-m;

    table {
      width: 100%;
    }

    .mark,
    .amount {
      color: $brand-primary;
      font-size: $size-s;
      letter-spacing: 1px;
    }

    .mark {
      font-weight: $fontBold;
      text-transform: uppercase;
      text-align: left;

      @include breakpoint($desktop) {
        font-weight: $fontReg;
      }

      span{
        font-weight: $fontReg;
      }
    }

    .amount {
      text-align: right;

      strong {
        font-weight: $fontBold;
      }
    }

    .totals-tax {
      line-height: $size-xl;
    }

    .grand.totals {
      .mark,
      .amount {
        padding-top: $size-m;
        border-top: 1px solid $border-light;
        font-size: $size-m;
        line-height: 20px;
        letter-spacing: 1px;
        color: $brand-primary;

        strong {
          color: $brand-primary;
        }
      }
    }

    .opc-block-shipping-information {
      background: transparent;
    }
  }

  .cart-totals {
    .totals {
      th span {
        font-weight: 300;
        font-size: 14px;
        padding-bottom: 0;
        border: 0;
      }

      .amount {
        display: block;
        line-height: 18px;
      }
    }

    tr.totals {
      &:not(.shipping):not(.sub) {
        display: none;
      }
    }
  }

  .checkout.methods {
    margin: 0;
    padding: 0;

    .item {
      list-style: none;

      .primary {
        width: 100%;
        margin-top: $size-xl;
      }
    }
  }
}

//
// accordion summary / seen on cart
//

.cart-container {
  .cart-summary {

    .title {
      &::after {
        content: "";
        background: url("../images/icons/icon-chevron-down-dark.svg");
        position: absolute;
        right: 0;
        top: -3px;
        height: $size-xl;
        width: $size-xl;
        transition: all .6s;
      }

      &.active {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    .cart-totals {
      display: none;
    }

    &.open {
      .title {
        &::after {
          display: none;
        }
      }

      .cart-totals {
        display: block;
      }
    }
  }
}

.cart-summary {
  
  .block.shipping {
    display: none;
  }

  .totals {

    th {
      padding-bottom: 8px;
    }
  }

  .totals.shipping {

    .label {
      margin-bottom: 0;

      @include breakpoint($desktop) {
        font-weight: 300;
      }
    }

    .value {
      display: none;
    }
  }

  .totals-tax {

    th {
      padding-bottom: 16px;
    }

    .amount {
      vertical-align: top;
    }
  }
}

.checkout-cart-index {
  .cart-summary {
    @include breakpoint($desktop) {
      display: none;
    }
  }
}

.opc-block-summary {
  display: flex;
  flex-direction: column;

  .title {
    order: 0;
    font-weight: 300;
    border-bottom: 0;
    padding: 0;
    margin: 0;
    font-size: 14px;

    &::after {
      display: none;
    }
  }

  .items-in-cart {
    order: 1;

    &:focus {
      outline: none;
    }

    ol {
      padding: 0;
    }

    li {
      list-style: none;
      padding: $size-m 0;
      border-bottom: 1px solid $border-light;
    }

    .product {
      .product-image-container {
        display: none;
      }

      .product-item-details {
        .details-qty {
          display: none;
        }
      }
    }
  }

  .table-totals {
    order: 2;
    margin-top: $size-m;

    .total-rules {
      display: none;
    }

    tr th,
    tr td {
      padding-bottom: $size-m;
    }

    tr {
      &:last-of-type {
        th,
        td {
          padding-bottom: 0;
        }
      }
    }

    .totals.discount {      
      .title.-enabled:after{
        width: 10px;
        height: 10px;
        background: none;
      }
      .price{
        white-space: pre;
      }
    }
  }

  .subtotal-incvat-title{
    font-size: 13px;
    font-weight: 300;
  }
  .subtotal-exvat-title::after{
    content: '(ex VAT)';
    font-size: 13px;
    font-weight: 300;
  }

  .total-incvat-title::after{
    content: '\AINC VAT';
    font-size: 12px;
    font-weight: 300;
    white-space: pre;
  }
}

.cart-summary .cart-totals .grand.totals .mark strong {
  font-size: $size-m;
  line-height: 20px;
}

.payment--methods {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: $size-xl 0 0; //small mobiles

  //larger mobiles
  @include breakpoint($smallMobile) {
    padding: $size-xl $size-xl 0;
  }

  //tablet
  @include breakpoint($mobile) {
    width: 45%;
    padding: $size-xl 0 0;
  }

  @include breakpoint($desktop) {
    width: 100%;
    padding: $size-xl 25px 0;
  }
}
