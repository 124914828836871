.catalog-product-view {

  .columns {
    @include breakpoint($desktop $desktop-mid) {
      padding: 0 $size-m;
    }
  }

  .page-title-wrapper,
  .product-info-price {
    padding: 0 $size-m;

    @include breakpoint($desktop) {
      padding: 0;
    }
  }

  .fotorama__stage {
    max-height: 414px;

    @include breakpoint($desktop) {
      padding-left: $size-m;
    }
  }

  // Commenting out for now as it was having a negative impact on the new product images
  // I can't see any downsides in removing it, but we may spot some once there are more new product images
  //
  // .fotorama__stage__frame .fotorama__img {
  //   max-height: none !important;
  // }

  .price-container h4 {
    color: $brand-primary;
  }

  .fotorama--fullscreen {
    .amasty-label-container {
      display: none !important;
    }
  }

  &.fotorama__fullscreen {

    .fotorama__stage {
      height: 100vh;
      max-height: 1408px; // to match the height of product_page_image_large set in view.xml
    }
  }
  
  .terms-link{
    text-decoration: underline;
  }
}
