//
// Found on Cart, Checkout
//

.block.discount {
  padding: 0 $size-m;
  margin-top: $size-xl;

  @include breakpoint($desktop) {
    padding: 0;
  }

  .title {
    padding: 19px $size-xl;
    background: $brand-background;
    border: 1px solid $border-lighter;
    position: relative;

    &:focus {
      outline: none;
    }

    &::after {
      content: "";
      background: url("../images/icons/icon-chevron-down-dark.svg");
      position: absolute;
      top: $size-m;
      right: 20px;
      height: $size-xl;
      width: $size-xl;
      transition: all .6s;
    }

    strong {
      @include subHeading;
    }
  }

  &.active {
    .title {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  form {
    background: $white;
    border: 1px solid $border-lighter;
    border-top: 0;
    padding: $size-xl;

    .fieldset {
      display: flex;
      justify-content: space-between;
    }

    .actions-toolbar {
      display: inline-block;
      width: 75px;
      margin-left: $size-2xs;

      .primary {
        background: $brand-senary;
        margin-top: 0;
        border-radius: 2px;
        width: 100%;
      }

      span {
        font-weight: $fontReg;
        letter-spacing: 1px;
      }
    }

    .applied {
      .actions-toolbar {
        width: 100px;
      }
    }

    .field {
      display: inline-block;
      width: 100%;

      input {
        margin-bottom: 0;
        color: $brand-primary;
        font-size: $size-s;
        letter-spacing: 1px;
        height: $size-4xl;
        border-radius: 2px;

        &::placeholder {
          color: $brand-primary;
          text-transform: uppercase;
          font-size: $size-s;
          letter-spacing: 1px;
        }
      }
    }
  }
}
