.customer-account-login,
.customer-account-forgotpassword {

  .page-main {
    @include breakpoint($desktop) {
      max-width: 568px;
      margin: 0 auto;
    }
  }

  .page-title {
    @include fancyHeading;
    font-weight: $fontBook;
    margin: 0 0 $size-2xl;
    padding: 0 $size-m;
    text-align: center;
  }

  .block-title {
    margin-bottom: $size-m;
  }

  .block-customer-login,
  .block-new-customer,
  .form.password.forget {
    padding: $size-xl $size-4xl $size-xl;
    background: $background;
    margin-bottom: $size-2xl;

    .field.note {
      margin-bottom: $size-m;
    }
  }

  .form-login {
    padding: 0;
    background-color: inherit;
    .field.note {
      margin-bottom: $size-m;
    }
  }

  button,
  a.action.create {
    width: 100%;
    margin-top: 0;
    background-color: $brand-senary;
    color: $white;
  }

  div.primary {
    margin-top: $size-m;
  }

  .secondary {
    margin-top: $size-2xl;
    display: flex;
    justify-content: center;
  }

  a.action.remind {
    background: inherit;
    font-size: $size-s;
    line-height: $size-l;
    color: $brand-primary;
    letter-spacing: 1px;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: $fontReg;
    padding: 0;
  }
}

/*Adyen Checkout Flow*/
.nexus_precheckoutlogin-index-index{
  .page-main{
    margin-top: 50px;
    max-width: 1168px;
    margin: 0 auto;

    .page.messages{
      margin-top:50px;
    }
    .block-customer-login,
    .block-customer-createaccount,
    .block-customer-guestcheckout{     
      margin: 24px 20px 24px 20px;
      .block-title{
        padding-top: 24px;
        margin-bottom: 12px;

        #block-customer-login-heading{
          font-size: 20px;
          font-family: "futura-pt", Helvetica, Arial, sans-serif;
          font-weight: 500;
        }
      }

      

      .block-content{
        max-width: 743px;
        
        form{          
          padding: 24px 24px 32px;
          border: 1px solid #E6DDEF;

          .mage-error{
            margin-bottom: 16px;
            color: red;
            font-size: 14px;
          }
          .password-box{
            position: relative;
          }
          .password-toggle-icon{
            max-width: 36px;
            position: absolute;
            right: 10px;
            top: 8px;
            cursor: pointer;
          }
          .password-toggle-icon.hidden{
            display: none;
          }

          .input-text.mage-error{
            margin-bottom: 5px;
          }         

          .field.note{
            margin-bottom: 10px;
          }

          input[type='radio']{
            margin-bottom: 0px;
          }

          .field-note{
            color:#2F1A45;
            font-size: 16px;
            font-weight: 400;
          }

          .option-label{
            margin-bottom: 5px;
            cursor: pointer;

            label{
              cursor: pointer;
            }
            
            .option-text{
              margin-left: 10px;
              font-family: "futura-pt", Helvetica, Arial, sans-serif;
              font-size: 16px;
              text-transform: uppercase;
              font-weight: 500;
              color: #2F1A45;
            }
          }

          .option-info{
            margin-left: 28px;
            font-family: "futura-pt", Helvetica, Arial, sans-serif;
            font-size: 16px;
            color: #2F1A45;
          }         

          label span{
            text-transform: none;
          }

          .actions-toolbar{
             .action.login.primary{
              width: 100%;
             }
          }

                    
          .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields {display: none;}

          .guestcheckout-form-fields{
            .input-text{
              margin-bottom: 10px;
            }
          }
          .createaccount-form-fields{
            .offers {
              margin-top: 24px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
            .offers-icon{
              max-width: 125px;
            }
          }          
        }

      }            
      
    }

    .active{
      .block-content{
        form{
          background: #E6DDEF;            

          .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields {
            display: block;
          }
          
        }          

      }
    }
  } 
}
/*Adyen Checkout Flow*/