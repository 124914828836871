:root {
  font-size: 16px;
}

body {
  @include fontSize(16px);
  font-family: $fontBody;
  font-weight: $fontReg;
  font-style: normal;
  color: $grey-dark;
}

a,
a:visited,
a:focus,
a:active {
  color: $brand-primary;
  text-decoration: none;
  outline: none;
  
  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
  
  &.action {
    color: $white;
  }
  
}

p,
ul,
ol {
  margin: 0 0 $size-xl;
  
  &:last-child {
    margin-bottom: 0;
  }
}

p {
  line-height: 20px;
  
  a,
  a:visited,
  a:focus,
  a:active {
    color: $brand-primary;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

[data-content-type="text"] {
  
  p {
    margin-bottom: $size-xl;
  }
  
  &:last-child {
    
    p:last-child {
      margin-bottom: 0;
    }
  }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: $brand-primary;
//   font-weight: $fontBold;
//   text-transform: uppercase;
//   letter-spacing: 1px;
// }

// %fancyHeading {
//   font-family: $fontHeading;
//   font-weight: $fontReg;
//   text-transform: none;
//   letter-spacing: normal;
// }

h1.h1-lust,
h2.lust-font {
  font-family: 'Lust', sans-serif;
  text-transform: none;
  color: $brand-primary;
  margin-top: 0;
  margin-bottom: 20px;
}

small {
  @include fontSize(14px);
  line-height: 20px;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  line-height: 0;
}

[data-content-type="divider"] {
  padding: 0 !important;
}

hr {
  border: 0 !important; // PB override
  height: 1px;
  line-height: 1px;
  background-color: $grey-lighter;
  margin: 0 0 $size-xl 0 !important; // PB override
}

address {
  color: $grey-dark;
  font-size: $size-m;	
  font-weight: 300;
  line-height: $size-xl;
  font-style: normal;
}

#maincontent .header-purple {
  color: $brand-primary;
}

//CMS Typography styling
.container--vibrant-purple .lust-font {
  line-height: 1.2;
}

h1.background {
  position: relative;
  z-index: 1;
  font-family: futura-pt;
  font-weight: 100;
  padding-top: 28px;
  font-size: 15px;
  padding-bottom: 26px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-align: center;
}