.plp-header {
  background: $brand-secondary;
  display: flex;
  flex-flow: row wrap;

  &__block {
    width: 100%;
    flex-basis: 100%;
    position: relative;

    &--text {
      padding: $size-2xl $size-m;

      @include breakpoint($desktop) {
        //width: calc((629 / 1440) * 100%); 
        //flex-basis: calc((629 / 1440) * 100%); // 629 and 1440 taken from designs to get an exact percentage as it doesn't follow the grid
        padding: 35px 0 40px 0;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--image {
      position: relative;
      display: flex;
      flex-flow: column nowrap;

      @include breakpoint($desktop) {
        width: calc((811 / 1440) * 100%);
        flex-basis: calc((811 / 1440) * 100%); // 811 and 1440 taken from designs to get an exact percentage as it doesn't follow the grid
      }

      .category-image {
        height: 100%;
        width: 100%;
      }

      .image {
        display: block;
        height: 100%;
        width: 100%;
        min-height: 208px; // for mobile
      }
    }

    &-constrainer {

      &--text {

        @include breakpoint($desktop) {
          width: 76%;
          flex-basis: 76%;
        }
      }
    }
  }

  .page-title {
    @include fancyHeading;
    margin: 0 0 $size-m;
    color: $white;
    font-weight: 400;
    text-align: center;

    @include breakpoint($desktop) {
      @include fontSize(40px);
      line-height: $size-6xl;
    }
  }

  .category-description {
    @include fontSize(16px);
    line-height: $size-xl;
    margin-bottom: $size-xl;
    color: $white;
    text-align: center;

    @include breakpoint($desktop) {
      margin-bottom: $size-2xl;
    }
  }

  &__cta-container {
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    @include breakpoint($mobile) {
      padding: 0 $size-xl;
    }

    @include breakpoint($desktop) {
      position: relative;
      padding: 0;
    }
  }

  &__cta {
    @extend %ghost-button;
    flex: 1;
    margin-right: $size-2xs;
    max-width: 180px;
    white-space: nowrap;

    @include breakpoint($desktop) {
      max-width: 160px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__second-heading {
    font-family: $fontHeading;
    font-size: $size-xl;
    line-height: $size-2xl;
    color: $white;
    font-weight: 400;
    margin: 0;
    position: absolute;
    left: $size-xl;
    bottom: $size-xl;

    @include breakpoint($desktop) {
      left: $size-2xl;
      bottom: $size-2xl;
      max-width: 643px; // taken from designs...
    }
  }

  &__sign-in {
    @include fontSize(14px);
    line-height: $size-l;
    padding: $size-2xs 0;
    background: $brand-tertiary;
    color: $white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 30; // increase due to Amasty Label
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: none;
    flex-flow: row nowrap;
    justify-content: center;

    @include breakpoint($desktop) {
      position: relative;
      padding: 4px 30px 4px 30px;
      width: auto;
    }

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: "";
      display: block;
      height: $size-2xl;
      width: $size-2xl;
      background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
      background-size: $size-xl;

      @include breakpoint($desktop) {
        display: none;
      }

    }

    span {
      line-height: $size-2xl;
    }
  }


  &--shop {
    background: $brand-quaternary;

    .category-description {
      margin-bottom: 0;
    }
  }
}
