.catalog-product-view,
.checkout-cart-index {
  .products-upsell,
  .products-crosssell {
    flex-basis: span(16);
  }

  .block.upsell {
    padding: 0;
    margin: $size-2xl 0 $size-4xl;

    @include breakpoint($desktop) {
      padding: 0;
      flex-basis: span(16);
    }
  }

  .block.crosssell {
    padding-top: $size-2xl;
    border-top: 1px solid $border-lighter;
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    position: relative !important;
  }

  .slick-track {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-width: 100%;

    &.constrained {
      justify-content: flex-start;

      li {
        margin-right: $size-2xl;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .slick-list {
    min-width: 100%;
    overflow: hidden;
  }

  .slick-dots {
    position: relative;
    margin: 0 auto;
    flex-basis: span(16);
    text-align: center;
    padding: 0;
    height: 2px;

    li {
      list-style: none;
      display: inline-block;
      background: $grey-lighter;
      width: $size-2xl;
      height: 2px;
      margin-right: $size-2xs;

      &.slick-active {
        background: $brand-primary;
      }

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        background: none;
        &:before {
          display: none;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .block-title.title {
    text-align: center;
    margin-bottom: $size-2xl;

    strong {
      @include mainHeading;
    }
  }

  .products-upsell,
  .products-crosssell {
    padding: 0;

    .pro-price {

      .price-including-tax {
        display: none;
      }
    }
  }

  .product-items {

    .product-item-name {
      padding: 0;
      margin-bottom: 6px;
      font-size: $size-s;
      line-height: $size-l;
      color: $brand-primary;
    }

    .product-item {
      margin-bottom: $size-2xl;

      @include breakpoint($desktop) {
        flex-basis: span(4);
        max-width: 268px;
        margin-right: 0;
      }

      &:focus {
        outline: 0;
      }

      @include breakpoint(max-width $desktop) {
        &:nth-of-type(odd) {
          margin-left: $size-m;
        }

        &:nth-of-type(even) {
          margin-right: $size-m;
        }
      }
    }

    .product-item-photo {
      outline: 0;
    }

    .product-item-actions {
      padding: 0 $size-m;

      .tocart {
        width: 90%;
      }
    }
  }
}

.checkout-cart-index {
  .block.crosssell {
    margin-top: $size-7xl;
    width: 100%;

    @include breakpoint($desktop) {
      margin-top: $size-2xl;
    }
  }
}
