.catalog-category-view {
  
  .loading-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    .loader {
      width: 100%;
      height: 100vh;
      
      img {
        left: calc(50% - 32px);
        top: calc(50vh - 32px);
        position: absolute;
      }
    }
  }

  .breadcrumbs {

    .items {
      padding-top: 0;
    }
  }

  .pay-later-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding: 0 1rem;

    > * {
      flex: 0 1 440px;
    }
  }
}
