.breadcrumbs {
  .items {
    padding: $size-m;

    @include breakpoint(1232px) {
      padding: $size-m 0;
    }

    .item {
      list-style: none;
      display: inline-block;
      color: $grey-dark;
      font-size: $size-s;
      letter-spacing: 1px;
      line-height: $size-l;
      position: relative;
      padding-right: $size-m;
      margin-right: $size-3xs;

      &::after {
        content: "/";
        position: absolute;
        right: 0;
      }

      &:last-of-type {
        padding-right: 0;
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }
}

.catalog-product-view .breadcrumbs .items .item.category7,
.catalog-product-view .breadcrumbs .items .item.category6,
.catalog-category-view .breadcrumbs .items .item.category7,
.catalog-category-view .breadcrumbs .items .item.category6 {
  display: none;
}

.catalog-category-view.category-nouveau-lashes-professional .breadcrumbs .items .item.category7,
.catalog-category-view.category-nouveau-lashes-consumer .breadcrumbs .items .item.category6 {
  display: inline-block;
}

.page-layout-cms_no-title {
  .breadcrumbs {
    display: none;
  }
}